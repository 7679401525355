<template>
  <div class="main">
    <el-card class="card-box" shadow="never">
      <div slot="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
          <el-breadcrumb-item>設定</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-card shadow="never">
        <div slot="header">設定通勤位置</div>
        <el-row>
          <el-col :span="14">
            <GmapMap
              v-loading="loading"
              :center="center"
              :zoom="zoom"
              :options="options"
              style="width: 100%; height: 750px"
              @click="setCenter($event.latLng.lat(), $event.latLng.lng())">
              <GmapMarker
                :position="marker.position"
                :clickable="false"
                :draggable="false" />
            </GmapMap>
          </el-col>
          <el-col :span="10">
            <el-form ref="form" class="mt-1" label-width="60px">
              <el-form-item label="經度">
                <el-input v-model="marker.position.lat" />
              </el-form-item>
              <el-form-item label="緯度">
                <el-input v-model="marker.position.lng" />
              </el-form-item>
              <el-form-item label="地址">
                <el-input
                  style="width: 100%"
                  v-model="location__name"
                  :readonly="true"></el-input>
              </el-form-item>
              <el-form-item label="搜尋">
                <GmapAutocomplete
                  class="el-input__inner"
                  @place_changed="setPlace"
                  :options="{ fields: ['geometry', 'formatted_address'] }"
                  :selectFirstOnEnter="true">
                </GmapAutocomplete>
              </el-form-item>
              <el-form-item label-width="0px">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-location"
                  style="margin-left: 2em"
                  @click="updatePos"
                  >更新
                </el-button>
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-edit-outline"
                  style="margin-left: 2em"
                  @click="resetPos"
                  >重設
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </el-card>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
  name: "Setting",
  data() {
    return {
      zoom: 15,
      marker: { position: { lat: 0, lng: 0 } },
      center: { lat: 0, lng: 0 },
      options: {
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        scrollwheel: true,
        clickableIcons: false,
      },
      loading: true,
      inputLocation: "",
      location__name: "",
      place: null,
      test: [0,0,0],
    };
  },

  methods: {
    setCenter(lat, lng) {
      this.marker.position = {
        lat: lat,
        lng: lng,
      };
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&location_type=ROOFTOP&result_type=street_address&key=AIzaSyBYOZZGQGW2ek1qM5Ipex_GKnlhx3rogE0`
      )
        .then((res) => res.json())
        .then((value) => {
          if (value.results.length)
            this.location__name = value.results[0].formatted_address;
        });
    },
    updatePos() {
      const locationRef = firebase.database().ref("company/location");
      locationRef
        .update({
          lat: this.marker.position.lat,
          lng: this.marker.position.lng,
          name: this.location__name,
        })
        .then(() => {
          this.$message({
            message: `已更新通勤位置`,
            type: "success",
          });
        })
        .catch((err) => {
          this.$message({
            message: `${err.message}`,
            type: "danger",
          });
        });
    },
    resetPos() {
      const locationRef = firebase.database().ref("company/location");
      locationRef
        .once("value")
        .then((snapshot) => {
          return snapshot.val();
        })
        .then((pos) => {
          this.center = {
            lat: pos.lat,
            lng: pos.lng,
          };
          this.marker.position = {
            lat: pos.lat,
            lng: pos.lng,
          };
          this.location__name = pos.name;
        })
        .catch(() => {
          navigator.geolocation.getCurrentPosition((position) => {
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.marker.position = this.center;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPlace(place) {
      this.place = place;
      this.usePlace();
    },
    usePlace() {
      this.marker.position = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      };
      this.location__name = this.place.formatted_address;
    },
  },
  mounted() {
    this.loading = true;
    const locationRef = firebase.database().ref("company/location");
    locationRef
      .once("value")
      .then((snapshot) => {
        return snapshot.val();
      })
      .then((pos) => {
        this.setCenter(pos.lat, pos.lng);
        this.center = {
          lat: pos.lat,
          lng: pos.lng,
        };
        this.marker.position = {
          lat: pos.lat,
          lng: pos.lng,
        };
        this.location__name = pos.name;
      })
      .catch(() => {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.marker.position = this.center;
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
