<template>
    <div class="main">
        <el-card class="card-box" shadow="never" v-loading="loading">
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item to="/employees"
                        >員工</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>瀏覽</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <el-card shadow="never" v-show="showDevOption">
                <div slot="header">
                開發者選項
                <el-button type="danger" @click="showDevOption = false"
                    >隱藏</el-button
                >
                </div>
                <el-button type="primary" @click="coverData">遮蓋資料</el-button>
            </el-card>

            <el-card shadow="never">
                <el-descriptions title="員工資訊" :column="3">
                    <el-descriptions-item label="全名">
                        <div name="empData">{{name}}</div>
                    </el-descriptions-item>
                    <el-descriptions-item label="英文名">
                        <div name="empData">{{eng_name}}</div>
                    </el-descriptions-item>
                    <el-descriptions-item label="郵箱">
                        <div class="email" name="empData">{{email}}</div>
                    </el-descriptions-item>
                    <el-descriptions-item label="電話號碼">
                        <div class="phoneNumber" name="empData">{{tel}}</div>
                    </el-descriptions-item>
                    <el-descriptions-item label="地址">
                        <div class="address" name="empData">{{address}}</div>
                    </el-descriptions-item>
                    <el-descriptions-item label="出生日期">
                        <div class="birthDate" name="empData">{{dob}}</div>
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>

            <el-card shadow="never" class="mt-1">
                <el-descriptions title="付款" :column="2">
                    <el-descriptions-item label="銀行" class="bankInfo">
                        <div name="empData">{{bank}}</div>
                    </el-descriptions-item>
                    <el-descriptions-item label="銀行帳戶" class="bankAc">
                        <div name="empData">{{bank_ac}}</div>
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>

            <el-card shadow="never" class="mt-1">
                <el-descriptions title="部門" :column="1">
                    <el-descriptions-item
                        v-for="(dept, i) in depts"
                        :key="i"
                        :label="dept.label"
                        ><el-tag size="small">{{ dept.value }}</el-tag></el-descriptions-item
                    >
                </el-descriptions>
            </el-card>
        </el-card>
    </div>
</template>
<style>

.cover {
  position: relative;
}

.cover::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 77%;
  height: 100%;
  background-color: black;
  z-index: 1;

}
</style>

<script>
import firebase from "firebase/compat/app";

export default {
    name: "",
    data() {
        return {
            name: null,
            eng_name: null,
            email: null,
            tel: null,
            address: null,
            dob: null,
            bank: null,
            bank_ac: null,
            loading_count: 0,
            depts: [],
            showDevOption: false,
            uid: "",
        };
    },
    methods: {
        coverData() {
            const empNames = document.querySelectorAll('[name="empData"]');
            empNames.forEach((element) => {
                // element.style.setProperty("text-decoration-line", "line-through");
                // element.style.setProperty("text-decoration-thickness", "1rem");
                element.classList.add("cover");
            });
        },
    },
    mounted() {
        this.loading_count++;
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.uid = user.uid;
                var dbRef = firebase.database().ref("users/" + this.uid);
                dbRef
                .once("value")
                .then((snapshot) => {
                    return snapshot.val();
                })
                .then((a) => {
                    if (a.role == "developer") {
                    this.showDevOption = true;
                    }
                });
            }
        });
        const empID = this.$route.params.id;
        const userRef = firebase.database().ref("users/" + empID);
        userRef
            .once("value")
            .then((snapshot) => snapshot.val())
            .then((value) => {
                this.name = value.name;
                this.eng_name = value.eng_name;
                this.email = value.email;
                this.tel = value.tel;
                this.address = value.address;
                this.dob = value.dob;
                this.bank = value.bank;
                this.bank_ac = value.bank_ac;
            })
            .finally(() => {
                this.loading_count--;
            });
        this.loading_count++
        const deptRef = firebase.database().ref("company/departments");
        deptRef
            .once("value")
            .then((snapshot) => snapshot.val())
            .then((value) => {
                const v = Object.values(value);
                v.forEach((dept) => {
                    if (
                        dept.members.manager == empID ||
                        dept.members.staff[empID] == 1
                    ) {
                        const isManager = dept.members.manager == empID;
                        this.depts.push({
                            label: dept.name,
                            value: isManager ? "主管" : "員工",
                        });
                    }
                });
            }).finally(()=>{
                this.loading_count--
            })
    },
    computed: {
        loading() {
            return this.loading_count != 0;
        },
    },
};
</script>
