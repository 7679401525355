<template>
  <div class="main">
    <el-card class="card-box" shadow="never" v-loading="loading">
      <div slot="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
          <el-breadcrumb-item>考勤管理</el-breadcrumb-item>
          <el-breadcrumb-item>更表設定</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div>
        <el-input
          placeholder="搜尋更表"
          type="text"
          style="float: left; width: 20em"
          v-model="search"
          clearable></el-input>
        <el-button
          @click="
            dialogVisible = !dialogVisible;
            editing = false;
            resetForm();
          "
          icon="el-icon-plus"
          circle
          plain
          style="float: left; margin-left: 1em"></el-button>
      </div>

      <el-table :data="pagedTableData">
        <el-table-column
          prop="name"
          label="更表名稱"
          sortable></el-table-column>
        <el-table-column label="星期日" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.sun.name == '休息' ? 'danger' : 'primary'">
              {{ scope.row.sun.name }} </el-tag
            ><br />
            {{ `${scope.row.sun.workTime} - ${scope.row.sun.offTime}` }}
          </template>
        </el-table-column>
        <el-table-column label="星期一" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.mon.name == '休息' ? 'danger' : 'primary'">
              {{ scope.row.mon.name }} </el-tag
            ><br />
            {{ `${scope.row.mon.workTime} - ${scope.row.mon.offTime}` }}
          </template>
        </el-table-column>
        <el-table-column label="星期二" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.tue.name == '休息' ? 'danger' : 'primary'">
              {{ scope.row.tue.name }} </el-tag
            ><br />
            {{ `${scope.row.tue.workTime} - ${scope.row.tue.offTime}` }}
          </template>
        </el-table-column>
        <el-table-column label="星期三" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.wed.name == '休息' ? 'danger' : 'primary'">
              {{ scope.row.wed.name }} </el-tag
            ><br />
            {{ `${scope.row.wed.workTime} - ${scope.row.wed.offTime}` }}
          </template>
        </el-table-column>
        <el-table-column label="星期四" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.thu.name == '休息' ? 'danger' : 'primary'">
              {{ scope.row.thu.name }} </el-tag
            ><br />
            {{ `${scope.row.thu.workTime} - ${scope.row.thu.offTime}` }}
          </template>
        </el-table-column>
        <el-table-column label="星期五" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.fri.name == '休息' ? 'danger' : 'primary'">
              {{ scope.row.fri.name }} </el-tag
            ><br />
            {{ `${scope.row.fri.workTime} - ${scope.row.fri.offTime}` }}
          </template>
        </el-table-column>
        <el-table-column label="星期六" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.sat.name == '休息' ? 'danger' : 'primary'">
              {{ scope.row.sat.name }} </el-tag
            ><br />
            {{ `${scope.row.sat.workTime} - ${scope.row.sat.offTime}` }}
          </template>
        </el-table-column>
        <el-table-column label="公眾假期休息" align="center">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.publicHoliday == true ? 'success' : 'danger'"
              >{{ scope.row.publicHoliday == true ? "是" : "否" }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="num" label="員工" sortable></el-table-column>
        <el-table-column label="操作" fixed="right" width="200px">
          <template slot-scope="scope">
            <el-button
              @click="handleEdit(scope)"
              class="el-icon-edit-outline"
              circle
              plain
              type="primary"></el-button>

            <el-button
              @click="handleStaff(scope)"
              class="el-icon-user-solid"
              circle
              plain
              type="success"></el-button>

            <el-popconfirm
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="確定要刪除此更表嗎？"
              style="margin-left: 1em"
              @confirm="confirmDelete(scope)">
              <el-button
                slot="reference"
                icon="el-icon-delete"
                circle
                plain
                type="danger"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-1">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <el-form v-model="form" label-width="100px">
        <el-form-item label="更表名稱">
          <el-input
            v-model="form.name"
            placeholder="輸入名稱"
            style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item v-if="!this.editing" label="員工">
          <el-select
            v-model="form.staff"
            multiple
            filterable
            placeholder="輸入關鍵詞"
            style="width: 100%">
            <el-option
              v-for="(item, i) in userList.none"
              :key="i"
              :label="item.label"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="星期日">
          <el-select v-model="form.sun">
            <el-option label="休息" value="rest" />
            <el-option
              v-for="(item, key) in Object.keys(shifts).filter(
                (e) => e != 'rest'
              )"
              :value="item"
              :key="key"
              :label="`${shifts[item].name} [${shifts[item].workTime} - ${shifts[item].offTime}]`" />
          </el-select>
        </el-form-item>
        <el-form-item label="星期一">
          <el-select v-model="form.mon">
            <el-option label="休息" value="rest" />
            <el-option
              v-for="(item, key) in Object.keys(shifts).filter(
                (e) => e != 'rest'
              )"
              :value="item"
              :key="key"
              :label="`${shifts[item].name} [${shifts[item].workTime} - ${shifts[item].offTime}]`" />
          </el-select>
        </el-form-item>
        <el-form-item label="星期二">
          <el-select v-model="form.tue">
            <el-option label="休息" value="rest" />
            <el-option
              v-for="(item, key) in Object.keys(shifts).filter(
                (e) => e != 'rest'
              )"
              :value="item"
              :key="key"
              :label="`${shifts[item].name} [${shifts[item].workTime} - ${shifts[item].offTime}]`" />
          </el-select>
        </el-form-item>
        <el-form-item label="星期三">
          <el-select v-model="form.wed">
            <el-option label="休息" value="rest" />
            <el-option
              v-for="(item, key) in Object.keys(shifts).filter(
                (e) => e != 'rest'
              )"
              :value="item"
              :key="key"
              :label="`${shifts[item].name} [${shifts[item].workTime} - ${shifts[item].offTime}]`" />
          </el-select>
        </el-form-item>
        <el-form-item label="星期四">
          <el-select v-model="form.thu">
            <el-option label="休息" value="rest" />
            <el-option
              v-for="(item, key) in Object.keys(shifts).filter(
                (e) => e != 'rest'
              )"
              :value="item"
              :key="key"
              :label="`${shifts[item].name} [${shifts[item].workTime} - ${shifts[item].offTime}]`" />
          </el-select>
        </el-form-item>
        <el-form-item label="星期五">
          <el-select v-model="form.fri">
            <el-option label="休息" value="rest" />
            <el-option
              v-for="(item, key) in Object.keys(shifts).filter(
                (e) => e != 'rest'
              )"
              :value="item"
              :key="key"
              :label="`${shifts[item].name} [${shifts[item].workTime} - ${shifts[item].offTime}]`" />
          </el-select>
        </el-form-item>
        <el-form-item label="星期六">
          <el-select v-model="form.sat">
            <el-option label="休息" value="rest" />
            <el-option
              v-for="(item, key) in Object.keys(shifts).filter(
                (e) => e != 'rest'
              )"
              :value="item"
              :key="key"
              :label="`${shifts[item].name} [${shifts[item].workTime} - ${shifts[item].offTime}]`" />
          </el-select>
        </el-form-item>
        <el-form-item label="公眾假期休息">
          <el-radio-group v-model="form.publicHoliday">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onSubmit">確定</el-button>
          <el-button @click="dialogVisible = !dialogVisible">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="staffDialog" title="編輯員工列表" width="60%">
      <el-form ref="staffForm" label-width="80px" v-model="staffForm">
        <el-form-item prop="name" label="名字">
          <el-input type="text" v-model="staffForm.name"></el-input>
        </el-form-item>
        <el-form-item prop="staff" label="員工">
          <el-transfer
            style="width: 100%"
            filterable
            filter-placeholder="輸入關鍵字"
            v-model="staffForm.staff"
            :data="userList.edit"
            :titles="[
              '員工',
              this.staffForm.name == null ? ' ' : this.staffForm.name,
            ]">
          </el-transfer>
        </el-form-item>
        <el-form-item>
          <el-button @click="onUpdateStaff()" type="primary" plain
            >更新</el-button
          >
          <el-button @click="staffDialog = !staffDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import app from "@/firebase";

export default {
  data() {
    return {
      table: [],
      shifts: [],
      filter_input: "",
      dialogVisible: false,
      staffDialog: false,
      form: {},
      staffForm: {
        rosterID: null,
        name: null,
        staff: [],
      },
      list: [],
      userList: [],
      staff: {
        options: [],
        lists: [],
        loading: false,
      },
      loading_count: 0,
      search: "",
      page: 1,
      pageSize: 9,
      editing: false,
      editingStaff: false,
    };
  },
  methods: {
    resetForm() {
      this.form = {
        name: "",
        staff: "",
        sun: "",
        mon: "",
        tue: "",
        wed: "",
        thu: "",
        fri: "",
        sat: "",
        publicHoliday: "",
      };
    },
    async getShifts() {
      this.shifts = [];
      this.loading_count++;
      await app.getShiftList().then((value) => {
        this.shifts = value;
        this.shifts["rest"] = {
          name: "休息",
          workTime: "00:00",
          offTime: "00:00",
        };
        this.loading_count--;
      });
    },
    loadTable() {
      this.table = [];
      this.loading_count++;
      firebase
        .database()
        .ref("company/attendance/roster")
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          Object.keys(value || []).forEach((rosterID) => {
            const roster = value[rosterID].shifts;
            this.table.push({
              name: value[rosterID].name,
              sun: {
                name: this.shifts[roster[0]].name,
                workTime: this.shifts[roster[0]].workTime,
                offTime: this.shifts[roster[0]].offTime,
                shiftID: roster[0],
              },
              mon: {
                name: this.shifts[roster[1]].name,
                workTime: this.shifts[roster[1]].workTime,
                offTime: this.shifts[roster[1]].offTime,
                shiftID: roster[1],
              },
              tue: {
                name: this.shifts[roster[2]].name,
                workTime: this.shifts[roster[2]].workTime,
                offTime: this.shifts[roster[2]].offTime,
                shiftID: roster[2],
              },
              wed: {
                name: this.shifts[roster[3]].name,
                workTime: this.shifts[roster[3]].workTime,
                offTime: this.shifts[roster[3]].offTime,
                shiftID: roster[3],
              },
              thu: {
                name: this.shifts[roster[4]].name,
                workTime: this.shifts[roster[4]].workTime,
                offTime: this.shifts[roster[4]].offTime,
                shiftID: roster[4],
              },
              fri: {
                name: this.shifts[roster[5]].name,
                workTime: this.shifts[roster[5]].workTime,
                offTime: this.shifts[roster[5]].offTime,
                shiftID: roster[5],
              },
              sat: {
                name: this.shifts[roster[6]].name,
                workTime: this.shifts[roster[6]].workTime,
                offTime: this.shifts[roster[6]].offTime,
                shiftID: roster[6],
              },
              publicHoliday: value[rosterID].publicHoliday,
              num: this.userList[rosterID]
                ? Object.keys(this.userList[rosterID]).length
                : 0,
              rosterID: rosterID,
            });
          });
        })
        .finally(() => {
          this.loading_count--;
        });
    },
    confirmDelete(scope) {
      const rosterID = scope.row.rosterID;
      if (this.userList[rosterID]) {
        this.userList[rosterID]
          .map((e) => e.key)
          .forEach((empID) => {
            firebase
              .database()
              .ref(`users/${empID}/rosterID`)
              .remove()
              .catch((err) => {
                this.$message({
                  message: `發生錯誤 ${err.code}: ${err.message}`,
                  type: "danger",
                });
              });
          });
      }
      firebase
        .database()
        .ref(`company/attendance/roster/${rosterID}`)
        .remove()
        .then(() => {
          this.$message({
            message: `成功刪除更表`,
            type: "success",
          });
          this.getStaff();
          this.loadTable();
        })
        .catch((err) => {
          this.$message({
            message: `發生錯誤 ${err.code}: ${err.message}`,
            type: "danger",
          });
        });
    },
    async handleStaff(scope) {
      this.editingStaff = true;
      this.staffForm.rosterID = scope.row.rosterID;
      this.staffForm.name = scope.row.name;
      this.staffForm.staff = [];
      await this.getStaff();
      this.staffDialog = true;
    },
    handleEdit(scope) {
      this.resetForm();
      this.editing = true;
      this.form.rosterID = scope.row.rosterID;
      this.form.name = scope.row.name;
      this.form.sun = scope.row.sun.shiftID;
      this.form.mon = scope.row.mon.shiftID;
      this.form.tue = scope.row.tue.shiftID;
      this.form.wed = scope.row.wed.shiftID;
      this.form.thu = scope.row.thu.shiftID;
      this.form.fri = scope.row.fri.shiftID;
      this.form.sat = scope.row.sat.shiftID;
      this.form.publicHoliday = scope.row.publicHoliday;
      this.dialogVisible = true;
    },
    async getStaff() {
      this.userList = {
        none: [],
        edit: [],
      };
      await firebase
        .database()
        .ref(`users`)
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          Object.keys(value).forEach((empID) => {
            if (value[empID].role == "user") {
              const rosterID = value[empID].rosterID;
              if (rosterID) {
                if (!this.userList[rosterID]) {
                  this.userList[rosterID] = [];
                }
                this.userList[rosterID].push({
                  label: value[empID].name,
                  key: empID,
                });
              } else {
                this.userList["none"].push({
                  label: value[empID].name,
                  key: empID,
                });
              }
            }
          });
        });
      if (this.editingStaff) {
        if (this.userList[this.staffForm.rosterID]) {
          this.userList[this.staffForm.rosterID].forEach((staff) =>
            this.staffForm.staff.push(staff.key)
          );
          this.userList.edit = this.userList[this.staffForm.rosterID].concat(
            this.userList.none
          );
        } else {
          this.userList.edit = this.userList.none;
        }
      }
    },
    onSubmit() {
      if (this.editing) {
        this.onUpdateRoster();
        return;
      }
      const staff = this.form.staff;
      const rosterRef = firebase.database().ref("company/attendance/roster");
      var update = {
        name: this.form.name,
        shifts: {
          0: this.form.sun,
          1: this.form.mon,
          2: this.form.tue,
          3: this.form.wed,
          4: this.form.thu,
          5: this.form.fri,
          6: this.form.sat,
        },
        publicHoliday: this.form.publicHoliday,
      };
      var key = rosterRef.push().key;

      rosterRef
        .child(key)
        .update(update)
        .then(() => {
          for (let i = 0; i < staff.length; i++) {
            this.loading_count++;
            var empID = staff[i];
            firebase
              .database()
              .ref(`users/${empID}`)
              .update({ rosterID: key })
              .catch((err) => {
                this.$message({
                  message: `發生錯誤 ${err.code}: ${err.message}`,
                  type: "error",
                });
                this.dialogVisible = false;
              })
              .finally(() => {
                this.loading_count--;
              });
          }
        })
        .then(() => {
          this.$message({
            message: "成功新增更表",
            type: "success",
          });
          this.resetForm();
          this.getStaff();
          this.loadTable();
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.$message({
            message: `發生錯誤 ${err.code}: ${err.message}`,
            type: "error",
          });
          this.dialogVisible = false;
        });
    },
    onUpdateStaff() {
      const removeList = this.userList.edit
        .map((e) => e.key)
        .filter((e) => !this.staffForm.staff.includes(e));
      removeList.forEach((empID) => {
        this.loading_count++;
        firebase
          .database()
          .ref(`users/${empID}/rosterID`)
          .remove()
          .finally(() => {
            this.loading_count--;
          });
      });

      this.staffForm.staff.forEach((empID) => {
        this.loading_count++;
        firebase
          .database()
          .ref(`users/${empID}`)
          .update({
            rosterID: this.staffForm.rosterID,
          })
          .finally(() => {
            this.loading_count--;
          });
      });

      this.loading_count++;
      firebase
        .database()
        .ref("company/attendance/roster/" + this.staffForm.rosterID)
        .update({
          name: this.staffForm.name,
        })
        .then(() => {
          this.$message({
            message: `編輯更表成功`,
            type: "success",
          });
        })
        .finally(() => {
          this.editing = false;
          this.staffDialog = false;
          this.getStaff();
          this.loadTable();
          this.loading_count--;
        });
    },
    onUpdateRoster() {
      this.loading_count++;
      firebase
        .database()
        .ref(`company/attendance/roster/${this.form.rosterID}`)
        .update({
          name: this.form.name,
          shifts: {
            0: this.form.sun,
            1: this.form.mon,
            2: this.form.tue,
            3: this.form.wed,
            4: this.form.thu,
            5: this.form.fri,
            6: this.form.sat,
          },
          publicHoliday: this.form.publicHoliday,
        })
        .then(() => {
          this.$message({
            message: `編輯更表成功`,
            type: "success",
          });
        })
        .finally(() => {
          this.editing = false;
          this.dialogVisible = false;
          this.loadTable();
          this.loading_count--;
        });
    },
    handleCurrentChange(c) {
      this.page = c;
    },
  },
  async mounted() {
    await this.getShifts();
    await this.getStaff();
    this.loadTable();
  },
  computed: {
    loading() {
      return this.loading_count != 0;
    },
    total() {
      return this.tableData.length;
    },
    tableData() {
      return this.table.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    pagedTableData() {
      return this.tableData.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
  },
};
</script>
