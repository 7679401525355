<template>
  <div class="main">
    <el-card class="card-box" shadow="never" v-loading="loading">
      <div slot="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
          <el-breadcrumb-item>薪酬管理</el-breadcrumb-item>
          <el-breadcrumb-item>薪酬組別</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <el-table :data="pagedTableData">
        <el-table-column
          prop="name"
          label="組別名稱"
          sortable></el-table-column>
        <el-table-column prop="num" label="員工" sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot="header">
            <el-input
              placeholder="搜尋組別"
              type="text"
              style="float: left; width: 20em"
              v-model="search"
              clearable></el-input>
            <el-button
              @click="dialogVisible = !dialogVisible"
              icon="el-icon-plus"
              circle
              style="float: left"
              type="text"></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope)"
              class="el-icon-edit-outline"
              circle
              plain
              type="primary"></el-button>

            <el-popconfirm
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="確定要刪除此組別嗎？"
              style="margin-left: 1em"
              @confirm="confirmDelete(scope)">
              <el-button
                slot="reference"
                icon="el-icon-delete"
                circle
                plain
                type="danger"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-1">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :visible.sync="dialogVisible" title="新增組別" width="30%">
      <el-form v-model="form" label-width="80px">
        <el-form-item label="組別">
          <el-input
            v-model="form.name"
            placeholder="輸入名稱"
            style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="員工" label-width="80px">
          <el-select
            v-model="form.staff"
            multiple
            filterable
            placeholder="輸入關鍵詞"
            style="width: 100%">
            <el-option
              v-for="(item, i) in userList.none"
              :key="i"
              :label="item.label"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onSubmit">確定</el-button>
          <el-button @click="dialogVisible = !dialogVisible">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="staffDialog" title="編輯組別" width="60%">
      <el-form ref="staffForm" label-width="80px" v-model="staffForm">
        <el-form-item prop="name" label="名字">
          <el-input type="text" v-model="staffForm.name"></el-input>
        </el-form-item>
        <el-form-item prop="staff" label="員工">
          <el-transfer
            style="width: 100%"
            filterable
            filter-placeholder="輸入關鍵字"
            v-model="staffForm.staff"
            :data="userList.edit"
            :titles="[
              '員工',
              this.staffForm.name == null ? ' ' : this.staffForm.name,
            ]">
          </el-transfer>
        </el-form-item>
        <el-form-item>
          <el-button @click="onUpdate()" type="primary" plain>更新</el-button>
          <el-button @click="staffDialog = !staffDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
  data() {
    return {
      table: [],
      filter_input: "",
      dialogVisible: false,
      staffDialog: false,
      form: {
        staff: "",
        name: "",
      },
      staffForm: {
        groupID: null,
        name: null,
        staff: [],
      },
      list: [],
      userList: [],
      staff: {
        options: [],
        lists: [],
        loading: false,
      },
      loading: true,
      search: "",
      page: 1,
      pageSize: 9,
      editing: false,
    };
  },
  methods: {
    loadTable() {
      this.table = [];
      this.loading = true;
      firebase
        .database()
        .ref("company/salary/group")
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          Object.keys(value).forEach((groupID) => {
            this.table.push({
              name: value[groupID].name,
              num: this.userList[groupID]
                ? Object.keys(this.userList[groupID]).length
                : 0,
              groupID: groupID,
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmDelete(scope) {
      const groupID = scope.row.groupID;
      if (this.userList[groupID]) {
        this.userList[groupID]
          .map((e) => e.key)
          .forEach((empID) => {
            firebase
              .database()
              .ref(`users/${empID}/salary/groupID`)
              .remove()
              .catch((err) => {
                this.$message({
                  message: `發生錯誤 ${err.code}: ${err.message}`,
                  type: "danger",
                });
              });
          });
      }
      firebase
        .database()
        .ref(`company/salary/group/${groupID}`)
        .remove()
        .then(() => {
          this.$message({
            message: `成功刪除組別`,
            type: "success",
          });
          this.getStaff();
          this.loadTable();
        })
        .catch((err) => {
          this.$message({
            message: `發生錯誤 ${err.code}: ${err.message}`,
            type: "danger",
          });
        });
    },
    async handleClick(scope) {
      this.editing = true;
      this.staffForm.groupID = scope.row.groupID;
      this.staffForm.name = scope.row.name;
      this.staffForm.staff = [];
      await this.getStaff();
      this.staffDialog = true;
    },
    async getStaff() {
      this.userList = {
        none: [],
        edit: [],
      };
      await firebase
        .database()
        .ref(`users`)
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          Object.keys(value).forEach((empID) => {
            if (value[empID].role == "user") {
              const groupID = value[empID].salary.groupID;
              if (groupID) {
                if (!this.userList[groupID]) {
                  this.userList[groupID] = [];
                }
                this.userList[groupID].push({
                  label: value[empID].name,
                  key: empID,
                });
              } else {
                this.userList["none"].push({
                  label: value[empID].name,
                  key: empID,
                });
              }
            }
          });
        });
      if (this.editing) {
        if (this.userList[this.staffForm.groupID]) {
          this.userList[this.staffForm.groupID].forEach((staff) =>
            this.staffForm.staff.push(staff.key)
          );
          this.userList.edit = this.userList[this.staffForm.groupID].concat(
            this.userList.none
          );
        } else {
          this.userList.edit = this.userList.none;
        }
      }
    },
    onSubmit() {
      const groupName = this.form.name;
      const staff = this.form.staff;
      const groupRef = firebase.database().ref("company/salary/group");
      var update = {
        name: groupName,
      };
      var key = groupRef.push().key;

      groupRef
        .child(key)
        .update(update)
        .then(() => {
          for (let i = 0; i < staff.length; i++) {
            this.loading_count++;
            var empID = staff[i];
            firebase
              .database()
              .ref(`users/${empID}/salary`)
              .update({ groupID: key })
              .catch((err) => {
                this.$message({
                  message: `發生錯誤 ${err.code}: ${err.message}`,
                  type: "error",
                });
                this.dialogVisible = false;
              })
              .finally(() => {
                this.loading_count--;
              });
          }
        })
        .then(() => {
          this.$message({
            message: "成功新增組別",
            type: "success",
          });

          this.getStaff();
          this.loadTable();
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.$message({
            message: `發生錯誤 ${err.code}: ${err.message}`,
            type: "error",
          });
          this.dialogVisible = false;
        });
    },
    onUpdate() {
      const removeList = this.userList.edit
        .map((e) => e.key)
        .filter((e) => !this.staffForm.staff.includes(e));
      removeList.forEach((empID) => {
        this.loading_count++;
        firebase
          .database()
          .ref(`users/${empID}/salary/groupID`)
          .remove()
          .finally(() => {
            this.loading_count--;
          });
      });

      this.staffForm.staff.forEach((empID) => {
        this.loading_count++;
        firebase
          .database()
          .ref(`users/${empID}/salary`)
          .update({
            groupID: this.staffForm.groupID,
          })
          .finally(() => {
            this.loading_count--;
          });
      });

      this.loading_count++;
      firebase
        .database()
        .ref("company/salary/group/" + this.staffForm.groupID)
        .update({
          name: this.staffForm.name,
        })
        .then(() => {
          this.$message({
            message: `編輯組別成功`,
            type: "success",
          });
        })
        .finally(() => {
          this.editing = false;
          this.staffDialog = false;
          this.getStaff();
          this.loadTable();
          this.loading_count--;
        });
    },
    handleCurrentChange(c) {
      this.page = c;
    },
  },
  async mounted() {
    await this.getStaff();
    this.loadTable();
  },
  computed: {
    total() {
      return this.tableData.length;
    },
    tableData() {
      return this.table.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    pagedTableData() {
      return this.tableData.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
  },
};
</script>
