// VUE ROUTER
import VueRouter from "vue-router";

// VIEWS
import Home from '@/views/Home';
import Company from '@/views/Company';
import Login from '@/views/Login';
import Departments from '@/views/Departments'
import Employees from '@/views/Employees';
import Attendance from '@/views/Attendance';
import Shift from '@/views/Shift';
import Roster from '@/views/Roster';
import Notification from '@/views/Notification';
import WorkAssign from '@/views/WorkAssign';
import Procurement from '@/views/Procurement';
import Claim from '@/views/Claim';
import Leave from '@/views/Leave';
import Setting from '@/views/Setting';
import Departments_Edit from '@/views/Departments_Edit';
import Employees_View from '@/views/Employees_View';
import OT_View from '@/views/OT';
import Adjustment from '@/views/Adjustment';  // 補卡
import Salary from '@/views/Salary';
import SalaryRule from '@/views/SalaryRule';
import SalaryGroup from '@/views/SalaryGroup';
import RegForm from '@/views/RegForm';

const routes = [
    { path: '/', component: Home, name: "Home"},
    { path: '/company', component: Company, name: "Company" },
    { path: '/login', component: Login, name: "Login" },
    { path: '/departments', component: Departments, name: "Departments" },
    { path: '/employees', component: Employees, name: "Employees" },
    { path: '/attendance', component: Attendance, name: "Attendance" },
    { path: '/shift', component: Shift, name: "Shift" },
    { path: '/roster', component: Roster, name: "Roster" },
    { path: '/notification', component: Notification, name: "Notification" },
    { path: '/assign-work', component: WorkAssign, name: 'Work Assign'},
    { path: '/claim', component: Claim, name: 'Claim' },
    { path: '/leave', component: Leave, name: "Leave" },
    { path: '/ot', component: OT_View, name: 'OT'},
    { path: '/procurement', component: Procurement, name: "Procurement" },
    { path: '/setting', component: Setting, name: 'Setting'},
    { path: '/departments/edit/:id', component: Departments_Edit, name: "Departments_Edit" },
    { path: '/employees/views/:id', component: Employees_View, name: "Employees_View"},
    { path: '/adjustment', component: Adjustment, name: 'Adjustment' },
    { path: '/salary', component: Salary, name: 'Salary' },
    { path: '/salary-rule', component: SalaryRule, name: 'Salary Rule' },
    { path: '/salary-group', component: SalaryGroup, name: 'Salary Group' },
    { path: '/reg-form', component: RegForm, name: 'Registration Form' },
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

export {router, VueRouter};