<template>
  <div class="main">
    <el-card shadow="never" class="card-box" v-loading="loading">
      <div slot="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
          <el-breadcrumb-item>薪酬管理</el-breadcrumb-item>
          <el-breadcrumb-item>薪酬規則</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <el-col :span="12">
        <el-row>
          <el-col :span="24">
            <el-select
              v-model="groupID"
              style="width: 85%"
              placeholder="請選擇薪酬組別..."
              filterable
              clearable
              @change="showGroupInfo">
              <el-option
                v-for="(group, i) in groups"
                :key="i"
                :label="group.label"
                :value="group.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>

      <el-table :data="this.rules">
        <el-table-column prop="name" label="規則名稱"> </el-table-column>
        <el-table-column prop="item" label="規則套用項目"> </el-table-column>
        <el-table-column prop="condition" label="規則套用條件">
        </el-table-column>
        <el-table-column prop="type" label="規則類型"> </el-table-column>
        <el-table-column prop="formula" label="規則公式"> </el-table-column>
        <el-table-column>
          <template slot="header">
            <el-button
              circle
              style="margin-left: 1em"
              icon="el-icon-plus"
              plain
              @click="handleClick()"></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              @click="editRule(scope)"
              circle
              icon="el-icon-edit-outline"
              plain
              style="margin-left: 1em"
              type="primary"></el-button>
            <el-popconfirm
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="確定要刪除此規則嗎？"
              style="margin-left: 1em"
              @confirm="confirmDelete(scope)">
              <el-button
                slot="reference"
                plain
                icon="el-icon-delete"
                type="danger"
                circle></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :visible.sync="ruleDialog" title="新增規則" width="40%">
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        style="margin-left: 5em; margin-right: 5em"
        label-width="120px">
        <el-form-item label="規則名稱" prop="name">
          <el-input
            type="text"
            v-model="form.name"
            placeholder="輸入規則名稱"></el-input>
        </el-form-item>
        <el-form-item label="規則套用項目" prop="item">
          <el-select
            v-model="form.item"
            placeholder="選擇規則套用項目"
            @change="changeItem()">
            <el-option label="請假" value="leave" />
            <el-option label="出勤" value="attendance" />
            <el-option label="補貼" value="allowance" />
          </el-select>
        </el-form-item>
        <el-form-item label="規則套用條件" prop="condition">
          <el-select v-model="form.condition" placeholder="選擇規則套用條件">
            <el-option
              v-for="a in conditions[form.item]"
              :key="a.value"
              :label="a.label"
              :value="a.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="規則類型" prop="type">
          <el-select v-model="form.type" placeholder="選擇規則類型">
            <el-option label="每日計算" value="daily" />
            <el-option
              v-if="form.item != 'attendance' && form.item != 'leave'"
              label="每月計算"
              value="monthly" />
          </el-select>
        </el-form-item>
        <el-form-item label="規則公式" prop="formula">
          <el-col :span="6">
            <el-form-item>
              <el-select v-model="form.operator" placeholder="選擇增減">
                <el-option label="增加" value="plus" />
                <el-option label="減少" value="minus" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
          <el-col :span="10">
            <el-form-item prop="value">
              <el-input
                type="text"
                v-model="form.value"
                placeholder="輸入數值" />
            </el-form-item>
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
          <el-col :span="6">
            <el-form-item prop="valueType">
              <el-select v-model="form.valueType" placeholder="選擇數值類型">
                <el-option label="元" value="dollar" />
                <el-option label="百分比(%)" value="percent" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onSubmit('form')"
            >確定</el-button
          >
          <el-button type="primary" plain @click="ruleDialog = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<style>
.el-form-item .el-select {
  width: 100%;
}
</style>

<script>
import firebase from "firebase/compat/app";

export default {
  data() {
    return {
      loading_count: 0,
      rules: [],
      groups: [],
      groupID: "",
      editing: false,
      form: {
        name: "",
        type: "",
        item: "",
        condition: "",
        operator: "",
        value: "",
        valueType: "",
      },
      ruleDialog: false,
      formRules: {
        name: [
          { required: true, message: "請輸入規則名稱", trigger: "change" },
        ],
        type: [
          { required: true, message: "請選擇規則類型", trigger: "change" },
        ],
        item: [
          { required: true, message: "請選擇規則套用項目", trigger: "change" },
        ],
        condition: [
          { required: true, message: "請選擇規則套用條件", trigger: "change" },
        ],
        operator: [
          { required: true, message: "請選擇增減", trigger: "change" },
        ],
        value: [{ required: true, message: "請輸入數值", trigger: "change" }],
        valueType: [
          { required: true, message: "請選擇數值類型", trigger: "change" },
        ],
      },
      conditions: {
        leave: [
          { label: "病假", value: "病假" },
          { label: "事假", value: "事假" },
          { label: "年假", value: "年假" },
          { label: "產假", value: "產假" },
          { label: "陪產假", value: "陪產假" },
          { label: "婚假", value: "婚假" },
          { label: "喪假", value: "喪假" },
          { label: "哺乳假", value: "哺乳假" },
          { label: "其他", value: "其他" },
        ],
        attendance: [
          { label: "早退", value: "早退" },
          { label: "遲到", value: "遲到" },
          { label: "曠工", value: "曠工" },
        ],
        allowance: [{ label: "補貼", value: "補貼" }],
      },
      label: {
        daily: "每日計算",
        monthly: "每月計算",
        leave: "請假",
        attendance: "出勤",
        Approved: "已審核",
        plus: "增加",
        minus: "減少",
        dollar: "元",
        percent: "%",
        allowance: "補貼",
      },
    };
  },
  methods: {
    handleClick() {
      if (this.groupID) {
        this.ruleDialog = true;
      } else {
        this.$alert('請先 選擇/新增 薪酬組別', '無法新增規則', {
          confirmButtonText: '確定',
        });
      }
    },
    changeItem() {
      this.form.condition = "";
      this.form.type = "";
    },
    editRule(scope) {
      const ruleID = scope.row.ruleID;
      this.ruleDialog = true;
      this.editing = true;
      this.loading_count++;
      firebase
        .database()
        .ref(`company/salary/group/${this.groupID}/rules/${ruleID}`)
        .once("value")
        .then((snapshot) => {
          return snapshot.val();
        })
        .then((v) => {
          this.form.name = v.name;
          this.form.type = v.type;
          this.form.item = v.item;
          this.form.condition = v.condition;
          this.form.operator = v.operator;
          this.form.value = v.value;
          this.form.valueType = v.valueType;
          this.form.ruleID = ruleID;
        })
        .catch((err) => {
          this.$message({
            message: `${err.code}: ${err.message}`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_count--;
        });
    },
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.loading_count++;
          const rule = {
            name: this.form.name,
            type: this.form.type,
            item: this.form.item,
            condition: this.form.condition,
            operator: this.form.operator,
            value: this.form.value,
            valueType: this.form.valueType,
          };

          const ruleRef = firebase
            .database()
            .ref(`company/salary/group/${this.groupID}/rules`);

          if (this.editing) {
            ruleRef
              .child(this.form.ruleID)
              .update(rule)
              .then(() => {
                this.$message({
                  message: "成功修改規則",
                  type: "success",
                });
              })
              .catch((err) => {
                this.$message({
                  message: `發生錯誤 ${err.code}: ${err.message}`,
                  type: "error",
                });
              })
              .finally(() => {
                this.showGroupInfo();
                this.ruleDialog = false;
                this.loading_count--;
              });
          } else {
            ruleRef
              .push(rule)
              .then(() => {
                this.$message({
                  message: "成功新增規則",
                  type: "success",
                });
              })
              .catch((err) => {
                this.$message({
                  message: `發生錯誤 ${err.code}: ${err.message}`,
                  type: "error",
                });
              })
              .finally(() => {
                this.showGroupInfo();
                this.ruleDialog = false;
                this.loading_count--;
              });
          }
        } else {
          return false;
        }
      });
    },
    confirmDelete(scope) {
      this.loading_count++;
      const ruleID = scope.row.ruleID;
      firebase
        .database()
        .ref(`company/salary/group/${this.groupID}/rules/${ruleID}`)
        .remove()
        .then(() => {
          this.$message({
            message: `刪除規則成功`,
            type: `success`,
          });
        })
        .catch((err) => {
          this.$message({
            message: `發生錯誤 ${err.code}: ${err.message}`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_count--;
          this.showGroupInfo();
        });
    },
    showGroupInfo() {
      this.loading_count++;
      this.rules = [];
      firebase
        .database()
        .ref(`company/salary/group/${this.groupID}/rules`)
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((rulesVal) => {
          for (const ruleID in rulesVal) {
            const rule = rulesVal[ruleID];
            this.rules.push({
              name: rule.name,
              type:
                this.label[rule.type] == null
                  ? rule.type
                  : this.label[rule.type],
              item:
                this.label[rule.item] == null
                  ? rule.item
                  : this.label[rule.item],
              condition:
                this.label[rule.condition] == null
                  ? rule.condition
                  : this.label[rule.condition],
              formula:
                (this.label[rule.operator] == null
                  ? rule.operator
                  : this.label[rule.operator]) +
                rule.value +
                (this.label[rule.valueType] == null
                  ? rule.valueType
                  : this.label[rule.valueType]),
              ruleID: ruleID,
            });
          }
        })
        .finally(() => {
          this.loading_count--;
        });
    },
    getGroups() {
      this.loading_count++;
      firebase
        .database()
        .ref("company/salary/group")
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          this.groups = [];
          Object.keys(value).forEach((v) => {
            this.groups.push({
              label: value[v].name,
              value: v,
            });
          });
        })
        .finally(() => {
          this.loading_count--;
        });
    },
  },
  computed: {
    loading() {
      return this.loading_count != 0;
    },
  },
  mounted() {
    this.getGroups();
  },
};
</script>
