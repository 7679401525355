<template>
    <div id="app">
        <el-container>
            <el-aside v-if="!($route.path == '/login' || $route.path == '/reg-form')"><Sidebar /></el-aside>
            <el-container>
                <router-view />
            </el-container>
        </el-container>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import Sidebar from '@/components/Sidebar';

export default {
    name: 'App',
    components: {
        Sidebar,
    },
    data() {
        return {};
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const isForm = Boolean(urlParams.get('form'));

        if (isForm) {
            this.$router.push('/reg-form');
        } else if (this.$route.path != '/login' && this.$route.path != '/reg-form') {
            firebase.auth().onAuthStateChanged((user) => {
                if (!user) {
                    this.$router.push('/login');
                }
            });
        }
    },
    watch: {
        $route() {
            firebase.auth().onAuthStateChanged((user) => {
                if (!user) {
                    if (!(this.$route.path == '/login' || this.$route.path == '/reg-form')) this.$router.push('/login');
                }
            });
        },
    },
};
</script>

<style>
* {
    user-select: none;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}
body {
    margin: auto;
    width: 100%;
    overflow: scroll;
}
.container {
    max-width: 1200px;
    margin: auto;
    width: unset;
}
.el-header {
    min-width: 100vh;
    height: 120px;
    width: unset !important;
    margin: 0 !important;
    padding: 0 0 !important;
}
.el-aside {
    min-height: 100vh;
    width: unset !important;
}
</style>
