<template>
  <div class="main">
    <el-card shadow="never" class="card-box" v-loading="loading">
      <div slot="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
          <el-breadcrumb-item>薪酬管理</el-breadcrumb-item>
          <el-breadcrumb-item>員工薪酬</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <el-form label-width="50px">
        <el-input placeholder="搜尋" style="float: left; width: auto" v-model="search" clearable></el-input>
        <el-button type="primary" plain style="margin-left: 1em" @click="getCSVJson">導出 CSV</el-button>
        <el-button plain icon="el-icon-caret-left" @click="changeMonth(-1)"> </el-button>
        <el-date-picker
          style="margin: 0 1em"
          v-model="selectMonth"
          type="month"
          placeholder="選擇月份"
          @change="changeMonth(0)">
        </el-date-picker>
        <el-button plain icon="el-icon-caret-right" @click="changeMonth(1)"> </el-button>
        <el-button
          style="margin-left: 1em"
          circle
          @click="updateAllSalary"
          icon="el-icon-refresh"
          plain
          type="success"></el-button>
      </el-form>

      <el-table :data="tableData" height="650">
        <el-table-column label="員工">
          <template slot-scope="scope">
            <el-row>
              <el-col :span="8">
                <el-avatar :size="40" :src="circleUrl"></el-avatar>
              </el-col>
              <el-col :span="16">
                <div>{{ scope.row.name }}</div>
                <div>{{ scope.row.eng_name }}</div>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="basic" label="基礎薪酬" align="right">
          <template slot-scope="scope">
            {{ basicSalary(scope.row) }}
            <el-tag type="success" v-if="scope.row.type == 'month'">
              {{ label(scope.row.type) }}
            </el-tag>
            <el-tag type="primary" v-if="scope.row.type == 'day'">
              {{ label(scope.row.type) }}
            </el-tag>
            <el-tag type="warning" v-if="scope.row.type == 'hour'">
              {{ label(scope.row.type) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="month" label="本月薪酬" align="right">
          <template slot-scope="scope">
            {{ monthSalary(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="commission" label="佣金" align="right">
          <template slot-scope="scope">
            {{ commission(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="adjust" label="額外調整" align="right">
          <template slot-scope="scope">
            {{ adjustedSalary(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="mpf" label="強積金" align="right">
          <template slot-scope="scope">
            {{ mpf(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="total" label="結算薪酬" align="right">
          <template slot-scope="scope">
            {{ totalSalary(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope)" class="el-icon-edit-outline" circle plain type="primary"></el-button>
            <el-button circle @click="viewInfo(scope)" icon="el-icon-view" plain type="info"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <table id="csv" v-show="false" style="border: 1px solid black; margin-top: 100%">
        <tbody>
          <tr>
            <td :colspan="8 + this.csv.columns.length * 2" rowspan="4" id="title"></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr id="columns">
            <td rowspan="2" colspan="1">姓名</td>
            <td colspan="2" rowspan="1">基礎薪酬(應出勤)</td>
            <td colspan="2" rowspan="1">本月薪酬(已出勤)</td>
            <td rowspan="2" colspan="1">佣金</td>
          </tr>
          <tr id="heading">
            <td></td>
            <td>日數[小時]</td>
            <td>薪酬</td>
            <td>日數[小時]</td>
            <td>薪酬</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </el-card>
    <el-dialog :visible.sync="infoDialog" title="員工薪酬詳情" width="40%">
      <p>員工名稱：{{ this.info.name }} - {{ this.info.eng_name }}</p>
      <p>
        基礎薪酬：{{ this.label(this.info.type) }} ${{ this.info.basic }} (應出勤 {{ this.info.workDay }} 日 /
        {{ this.info.workHour }} 小時)
      </p>
      <p>本月薪酬：{{ this.info.month }} (已出勤 {{ this.info.workedDay }} 日 / {{ this.info.workedHour }} 小時)</p>
      <p>佣金：{{ this.info.commission }}</p>
      <p>額外調整：{{ this.info.adjust }}</p>
      <p>強積金：{{ this.info.mpf }}</p>
      <p>結算薪酬：{{ this.info.total }}</p>
      薪酬調整詳情：
      <el-card>
        <div v-for="(log, index) in this.info.logs" :key="index">{{ log }}</div>
      </el-card>
      <br /><el-button type="primary" plain @click="infoDialog = false">確定</el-button>
    </el-dialog>

    <el-dialog :visible.sync="editDialog" width="30%" title="薪酬設定">
      <el-form v-model="editForm" label-width="60px">
        <el-form-item label="佣金">
          <el-input-number
            v-model="editForm.commission"
            placeholder="輸入佣金"
            :precision="2"
            :step="0.1"
            :min="0"
            step-strictly
            style="width: 100%"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onSubmit">確定</el-button>
          <el-button @click="editDialog = !editDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import app from "@/firebase";
import $ from "jquery";

import "table2excel";
const Table2Excel = window.Table2Excel;

export default {
  data() {
    return {
      table: [],
      rules: [],
      details: [],
      records: [],
      groups: [],
      roster: [],
      shift: [],
      claims: [],
      publicHolidays: [],
      info: {},
      loading_count: 0,
      selectMonth: new Date(),
      selectMonthStr: "",
      search: "",
      page: 1,
      pageSize: 8,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      infoDialog: false,
      editDialog: false,
      empDetailID: "",
      workday: 0,
      labelList: {
        leave: "請假",
        attendance: "出勤",
        allowance: "補貼",
        day: "日薪",
        month: "月薪",
        hour: "時薪",
      },
      csv: {
        columns: [],
        data: [],
      },
      editForm: {
        commission: 0,
      },
    };
  },
  methods: {
    exportCSV() {
      var table2excel = new Table2Excel();
      table2excel.export(document.querySelectorAll("table#csv"));
    },
    getCSVJson() {
      this.loading_count++;
      this.csv.data = [];

      $("#title").append(`${app.formatDate(new Date().getTime()).slice(0, 7)}薪酬報表`);

      this.csv.columns.forEach((heading) => {
        $("#columns").append(`<td colspan="2" rowspan="1">${heading}</td>`);
        $("#heading").append(`<td>次數</td><td>增減</td>`);
      });
      $("#columns").append(
        `
          <td colspan="1" rowspan="2">強積金</td>
          <td colspan="1" rowspan="2">結算薪酬</td>
        `
      );

      this.table.forEach((user) => {
        const userObj = user;
        var dataObj = {};
        dataObj["name"] = userObj.name.replaceAll(" ", "_");

        this.csv.data.push(dataObj);
        const userObjRecord = user.records[this.selectMonthStr];

        $("table#csv>tbody").append(
          `
            <tr id='${dataObj.name}'>
              <td>${dataObj.name}</td>
              <td>${userObjRecord.workDay}[${userObjRecord.workHour}]</td>
              <td>${
                user.type == "month"
                  ? user.basic
                  : user.type == "day"
                  ? user.basic * userObjRecord.workDay
                  : user.basic * userObjRecord.workHour
              }</td>
              <td>${userObjRecord.workedDay}[${userObjRecord.workedHour}]</td>
              <td>${user.month}</td>
              <td>${userObjRecord.commission}</td>
          `
        );

        Object.values(dataObj).forEach((name) => {
          this.csv.columns.forEach((heading) => {
            $(`tr#${name}`).append(
              `
                <td>${userObjRecord[heading] ? userObjRecord[heading].amount : "N/A"}</td>
                <td>${userObjRecord[heading] ? this.formatDollar(userObjRecord[heading].adjust) : "N/A"}</td>
              `
            );
          });
          $(`tr#${name}`).append(
            `
              <td>${this.formatDollar(user.mpf * -1)}</td>
              <td>${this.formatDollar(user.total)}</td>
            `
          );
        });

        $("table#csv>tbody").append(`</tr></tr>`);
      });
      this.loading_count--;
      this.exportCSV();
    },
    formatDollar(dollar) {
      return this.roundingDollar(dollar).toFixed(2);
    },
    roundingDollar(dollar) {
      return Math.round(dollar * 10, 1) / 10;
    },
    mpfCalc(income, haveMpf) {
      if (income < 7100 || !haveMpf) return 0;
      if (income > 30000) return 1500;
      return Math.round(income * 0.05 * 10, 1) / 10;
    },
    changeMonth(change) {
      this.loading_count++;
      this.selectMonth = new Date(this.selectMonth.setMonth(new Date(this.selectMonth).getMonth() + change));
      this.selectMonthStr = new Date(this.selectMonth - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 7);
      let lastday = new Date(this.selectMonth.getFullYear(), this.selectMonth.getMonth() + 1, 0);
      this.table.forEach((row) => {
        let haveMpf = Math.ceil((lastday - new Date(row.empDate)) / (1000 * 60 * 60 * 24)) >= 60;
        this.$set(row, "haveMpf", haveMpf);
        if (!row.records[this.selectMonthStr]) {
          this.$set(row.records, this.selectMonthStr, {});
        }
        let record = row.records[this.selectMonthStr];
        this.$set(record, 'commission', record?.commission || 0);
        this.$set(record, 'adjust', record?.adjust || 0);
        this.$set(record, 'workDay', record?.workDay || 0);
        this.$set(record, 'workedDay', record?.workedDay || 0);
        this.$set(record, 'workHour', record?.workHour || 0);
        this.$set(record, 'workedHour', record?.workedHour || 0);
        this.$set(record, 'daily', record?.daily || 0);
        this.$set(record, 'logs', record?.logs || []);
        record.adjust = record?.adjust || 0;
        record.workDay = record?.workDay || 0;
        record.workedDay = record?.workedDay || 0;
        record.workHour = record?.workHour || 0;
        record.workedHour = record?.workedHour || 0;
        record.daily = record?.daily || 0;
        record.logs = record?.logs || [];
      });
      this.loading_count--;
    },
    handleEdit(scope) {
      this.loading_count++;
      this.editForm = {
        commission: 0,
        empID: scope.row.empID,
      };
      this.editForm.commission = scope.row.records[this.selectMonthStr].commission;
      this.editDialog = true;
      this.loading_count--;
    },
    viewInfo(scope) {
      this.infoDialog = true;
      this.info = [];
      this.info.name = scope.row.name;
      this.info.eng_name = scope.row.eng_name;
      this.info.workDay = scope.row.records[this.selectMonthStr].workDay;
      this.info.workedDay = scope.row.records[this.selectMonthStr].workedDay;
      this.info.workHour = scope.row.records[this.selectMonthStr].workHour;
      this.info.workedHour = scope.row.records[this.selectMonthStr].workedHour;
      this.info.basic = this.formatDollar(scope.row.basic);
      this.info.type = scope.row.type;
      this.info.daily = this.formatDollar(scope.row.daily);
      this.info.month = this.formatDollar(scope.row.month);
      this.info.commission = this.formatDollar(scope.row.commission);
      this.info.adjust = this.formatDollar(scope.row.adjust);
      this.info.mpf = this.formatDollar(scope.row.mpf);
      this.info.total = this.formatDollar(scope.row.total);
      this.empDetailID = scope.row.empID;
      this.info.logs = scope.row.records[this.selectMonthStr].logs;
    },
    onSubmit() {
      this.loading_count++;
      firebase
        .database()
        .ref(`users/${this.editForm.empID}/salary/records/${this.selectMonthStr}`)
        .update({
          commission: this.editForm.commission,
        })
        .then(() => {
          this.$message({
            message: "成功更改設定",
            type: "success",
          });
          this.table[this.table.findIndex((e) => e.empID == this.editForm.empID)].records[
            this.selectMonthStr
          ].commission = this.editForm.commission;
          this.loading_count--;
          this.editDialog = false;
        })
        .catch((err) => {
          this.$message({
            message: `發生錯誤 ${err.code}: ${err.message}`,
            type: "error",
          });
          this.loading_count--;
          this.editDialog = false;
        });
    },
    label(input) {
      return this.labelList[input] || input;
    },
    addLog(date, rule, adjust) {
      adjust = this.formatDollar(adjust);
      const log = `[${date}] 因 [${this.label(rule.item)}]${rule.condition} ${
        rule.operator == "plus" ? "增加" : "減少"
      } ${rule.valueType == "dollar" ? adjust + " 元" : rule.value + " %"}${
        rule.valueType == "percent" ? ` (${adjust} 元)` : ""
      }`;
      return log;
    },
    async getInitData() {
      this.changeMonth(0);
      this.publicHolidays = app.getPublicHolidays(new Date().getFullYear());
      this.roster = await app.getRosterList();
      this.shift = await app.getShiftList();
      await this.getRecords();
      this.changeMonth(0);
    },
    async getRecords() {
      await firebase
        .database()
        .ref(`users`)
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          Object.keys(value).forEach((empID) => {
            const user = value[empID];
            if (user.role != "administrator" && user.role != "developer" && user.rosterID) {
              let salaryRecords = user.salary?.records || [];
              let monthRecord = salaryRecords?.[this.selectMonthStr] || {};
              monthRecord.commission = monthRecord?.commission || 0;
              monthRecord.adjust = monthRecord?.adjust || 0;
              monthRecord.workDay = monthRecord?.workDay || 0;
              monthRecord.workedDay = monthRecord?.workedDay || 0;
              monthRecord.workHour = monthRecord?.workHour || 0;
              monthRecord.workedHour = monthRecord?.workedHour || 0;
              monthRecord.daily = monthRecord?.daily || 0;
              monthRecord.logs = monthRecord?.logs || [];

              this.table.push({
                name: user.name,
                eng_name: user.eng_name,
                empID: empID,
                type: user.salary.type,
                basic: parseFloat(user.salary.basic),
                rosterID: user.rosterID,
                groupID: user.salary.groupID,
                empDate: user.empDate,
                records: salaryRecords,
              });
            }
          });
        });
      await firebase
        .database()
        .ref(`company/salary/group`)
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          for (const groupID in value) {
            const group = value[groupID];
            this.groups[groupID] = [];
            for (let ruleID in group.rules) {
              const rule = group.rules[ruleID];
              if (!this.groups[groupID][rule.item]) {
                this.groups[groupID][rule.item] = [];
              }
              this.groups[groupID][rule.item].push(rule);
            }
          }
        });
    },
    async updateAllSalary() {
      this.loading_count++;
      for (let i = 0; i < this.tableData.length; i++) {
        let empRef = this.tableData[i];
        let empRecords = empRef.records[this.selectMonthStr];
        empRecords.workDay = 0;
        empRecords.workedDay = 0;
        empRecords.workHour = 0;
        empRecords.workedHour = 0;
        this.records[empRef.empID] = [];
        const roster = this.roster[empRef.rosterID];
        for (
          let day = new Date(this.selectMonthStr);
          day.getMonth() == this.selectMonth.getMonth();
          day.setDate(day.getDate() + 1)
        ) {
          const formattedDate = app.formatDate(day.getTime());
          if (
            roster.shifts[day.getDay()] == "rest" ||
            (roster.publicHoliday && this.publicHolidays.includes(formattedDate))
          ) {
            continue;
          } else {
            const shift = this.shift[roster.shifts[day.getDay()]];
            empRecords.workDay++;
            empRecords.workHour += shift.workHour;
          }
        }
        empRecords.daily =
          empRef.type == "month"
            ? Math.round((parseFloat(empRef.basic) / empRecords.workDay) * 10, 1) / 10
            : parseFloat(empRef.salary);

        await firebase
          .database()
          .ref(`users/${empRef.empID}/attendance/record`)
          .once("value")
          .then((s) => s.val())
          .then((v) => {
            Object.keys(v).forEach((date) => {
              if (date.slice(0, 7) == this.selectMonthStr) {
                const current = v[date];
                const shift = this.shift[roster.shifts[new Date(date).getDay()]];
                if (shift != undefined) {
                  let workedHour =
                    current.leaveTimestamp && current.workTimestamp
                      ? Math.ceil((current.leaveTimestamp - current.workTimestamp) / (1000 * 60 * 60))
                      : 0;
                  if (workedHour > shift.workHour) {
                    workedHour = shift.workHour;
                  }
                  if (workedHour > 0) {
                    empRecords.workedDay++;
                    empRecords.workedHour += workedHour;
                  }
                  this.records[empRef.empID][date] = {
                    workStat: current.workStat,
                    leaveStat: current.leaveStat,
                    workedHour: workedHour,
                    salary: empRef.type == "hour" ? empRef.basic * workedHour : empRecords.daily,
                    adjust: 0,
                    logs: [],
                  };
                  this.records[empRef.empID]["每月"] = {
                    logs: [],
                  };
                }
              }
            });
          });
        this.updateSalary(empRef);
        empRecords.logs = [];
        Object.values(this.records[empRef.empID]).forEach((record) => {
          if (record && record.logs) {
            record.logs.forEach((log) => {
              empRecords.logs.push(log);
            });
          }
        });
        this.loading_count++;
        firebase
          .database()
          .ref(`users/${empRef.empID}/salary/records/${this.selectMonthStr}`)
          .set(empRecords)
          .catch((err) => console.error(err))
          .finally(() => this.loading_count--);
      }
      this.loading_count--;
    },
    updateSalary(empRef) {
      const empID = empRef.empID;
      if (!empRef.records) {
        empRef.records = [];
      }
      if (!empRef.records[this.selectMonthStr]) {
        empRef.records[this.selectMonthStr] = {
          adjust: 0,
          commission: 0,
        };
      }
      let record = empRef.records[this.selectMonthStr];
      record.adjust = 0;

      if (this.records[empID]) {
        const rules = this.groups[empRef.groupID];
        if (rules) {
          if (rules.attendance) {
            this.processRule(empID, rules.attendance, empRef);
          }
          if (rules.leave) {
            this.processRule(empID, rules.leave, empRef);
          }
          if (rules.allowance) {
            this.processRule(empID, rules.allowance, empRef);
          }
        }
      }
      this.processClaim(empID, empRef);
    },
    processRule(empID, rules, empRef) {
      rules.forEach((rule) => {
        let record = empRef.records[this.selectMonthStr];
        if (!record[rule.condition]) record[rule.condition] = { amount: 0, adjust: 0 };
        let adjustTotal = 0;
        if (rule.type == "daily") {
          for (const date in this.records[empID]) {
            const currDate = this.records[empID][date];

            if (currDate.workStat == rule.condition || currDate.leaveStat == rule.condition) {
              let adjust = 0;
              if (rule.valueType == "dollar") {
                adjust = rule.value * (rule.operator == "plus" ? 1 : -1);
              } else {
                adjust = (currDate.salary * (rule.value * (rule.operator == "plus" ? 1 : -1))) / 100;
              }
              record[rule.condition].amount++;
              record[rule.condition].adjust += adjust;
              this.records[empID][date].adjust += adjust;
              adjustTotal += adjust;
              this.records[empID][date].logs.push(this.addLog(date, rule, Math.abs(adjust)));
            }
          }
        } else {
          if (rule.item == "allowance") {
            let adjust = 0;
            if (rule.valueType == "dollar") {
              adjust = rule.value * (rule.operator == "plus" ? 1 : -1);
            } else {
              adjust = (empRef.salary * empRef.workDay * (rule.value * (rule.operator == "plus" ? 1 : -1))) / 100;
            }
            record[rule.condition].amount++;
            record[rule.condition].adjust += adjust;
            adjustTotal += adjust;
            this.records[empID]["每月"].logs.push(this.addLog("每月", rule, Math.abs(adjust)));
          }
        }
        if (!this.csv.columns.includes(rule.condition) && record[rule.condition].amount > 0)
          this.csv.columns.push(rule.condition);
        record.adjust += adjustTotal;
      });
    },
    processClaim(empID, empRef) {
      if (this.claims[empID]) {
        if (!empRef["報銷"]) empRef["報銷"] = { amount: 0, adjust: 0 };
        this.claims[empID].forEach((claim) => {
          if (!this.records[empID][claim.date]) {
            this.records[empID][claim.date] = {
              logs: [],
            };
          }
          empRef["報銷"].amount++;
          empRef["報銷"].adjust += claim.amount;
          empRef.adjust += claim.amount;
          const log = `[${claim.date}] 因 [其他]報銷 增加 ${claim.amount} 元`;
          this.records[empID][claim.date].logs.push(log);
        });
        if (!this.csv.columns.includes("報銷") && empRef["報銷"].amount > 0) this.csv.columns.push("報銷");
      }
    },
  },
  computed: {
    loading() {
      return this.loading_count != 0;
    },
    total() {
      return this.tableData.length;
    },
    tableData() {
      return this.table.filter(
        (data) =>
          (!this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase()) ||
            data.eng_name.toLowerCase().includes(this.search.toLowerCase())) &&
          data.empDate.slice(0, 7) <= this.selectMonthStr &&
          data.groupID
      );
    },
    basicSalary() {
      return (row) => {
        return this.formatDollar(row.basic);
      };
    },
    monthSalary() {
      return (row) => {
        switch (row.type) {
          case "month":
            row.month = row.basic;
            break;
          case "day":
            row.month = row.basic * row.records[this.selectMonthStr]?.workedDay || 0;
            break;
          case "hour":
            row.month = row.basic * row.records[this.selectMonthStr]?.workedHour || 0;
            break;
        }
        return this.formatDollar(row.month);
      };
    },
    commission() {
      return (row) => {
        row.commission = row.records[this.selectMonthStr]?.commission || 0;
        return this.formatDollar(row.commission);
      };
    },
    adjustedSalary() {
      return (row) => {
        row.adjust = row.records[this.selectMonthStr]?.adjust || 0;
        return this.formatDollar(row.adjust);
      };
    },
    mpf() {
      return (row) => {
        row.mpf = this.mpfCalc(row.month + row.commission + row.adjust, row.haveMpf);
        if (row.haveMpf) {
          return this.formatDollar(row.mpf);
        } else {
          return "入職未滿60日";
        }
      };
    },
    totalSalary() {
      return (row) => {
        row.total = row.month + row.commission + row.adjust - row.mpf;
        return this.formatDollar(row.total);
      };
    },
    empDetail() {
      return this.details[this.empDetailID] || [];
    },
  },
  async mounted() {
    this.loading_count++;
    await this.getInitData();
    this.loading_count--;
  },
};
</script>
