<template>
    <div class="main">
        <el-card
            shadow="never"
            class="card-box main"
            style="border-radius: 30px !important"
            v-loading="loading"
        >
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>主頁</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card id="panel">
                <el-descriptions title="公司訊息">
                    <el-descriptions-item label="公司名稱">{{
                        name
                    }}</el-descriptions-item>
                    <el-descriptions-item label="電話號碼">{{
                        tel
                    }}</el-descriptions-item>
                    <el-descriptions-item label="電郵地址">{{
                        email
                    }}</el-descriptions-item>
                    <el-descriptions-item label="地址">
                        {{ address }}
                    </el-descriptions-item>
                    <el-descriptions-item label="公司網址">
                        {{ url }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
            <!-- <div style="font-size: 36px; font-weight: 300; margin-top: 1em">
                公司
            </div>
            <el-row :gutter="100" style="margin-top: 1em">
                <HomeColumn title="員工" amount="1000" icon="el-icon-user" />
                <HomeColumn
                    title="部門"
                    amount="1000"
                    icon="el-icon-s-cooperation"
                />
            </el-row>
            <div
                style="
                    font-size: 36px;
                    font-weight: 300;
                    margin-left: 8px;
                    margin-top: 1em;
                    margin-right: 8px;
                    text-align: left;
                "
            >
                今日
            </div>
            <el-row :gutter="50" style="margin-top: 1em">
                <HomeColumn title="員工" amount="1000" icon="el-icon-user" />
                <HomeColumn title="員工" amount="1000" icon="el-icon-user" />
                <HomeColumn title="員工" amount="1000" icon="el-icon-user" />
                <HomeColumn title="員工" amount="1000" icon="el-icon-user" />
                <HomeColumn title="員工" amount="1000" icon="el-icon-user" />
            </el-row> -->
        </el-card>
    </div>
</template>
<style>
.d-flex {
    display: flex !important;
}
.justify-content-center {
    justify-content: center !important;
}
.align-items-center {
    align-items: center !important;
}
#panel.el-card {
    border-radius: 15px !important;
}
#panel.el-card:hover {
    border-radius: 45px !important;
}
.el-row .el-card {
    border-radius: 15px !important;
}
.el-row .el-card:hover {
    border-radius: 45px !important;
}
</style>
<script>
// import HomeColumn from "@/components/HomeColumn";
import firebase from "firebase/compat/app";
export default {
    name: "Home",
    components: {
        // HomeColumn,
    },
    data() {
        return {
            name: "",
            tel: "",
            email: "",
            address: "",
            url: "",
            loading: true,
        };
    },
    mounted() {
        this.getCompanyInfo();
    },
    methods: {
        getBgColor() {
            let temp = parseInt(Math.random() * 255).toString(16);
            if (temp.length == 1) {
                return "0" + temp;
            }
            return temp;
        },
        getCompanyInfo() {
            const companyInfoRef = firebase.database().ref("company/info");
            companyInfoRef
                .once("value")
                .then((snapshot) => {
                    return snapshot.val();
                })
                .then((v) => {
                    this.name = v.name;
                    this.tel = v.phone;
                    this.email = v.email;
                    this.address = v.address;
                    this.url = v.website;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
