<template>
    <div class="main">
        <el-card class="card-box" shadow="never" v-loading="loading">
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>考勤管理</el-breadcrumb-item>
                    <el-breadcrumb-item>出勤紀錄</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <el-card shadow="never" v-show="showDevOption">
                <div slot="header">
                    開發者選項
                    <el-button id="hide-btn" type="danger" @click="showDevOption = false">隱藏</el-button>
                </div>
                <el-button id="update-record-btn" type="primary" @click="autoUpdateRecord">更新出勤紀錄</el-button>
                <el-button id="hide-record-btn" type="primary" @click="coverData">遮蓋資料</el-button>
            </el-card>

            <el-card shadow="never">
                <div slot="header">篩選</div>
                <el-form label-width="50px">
                    <el-button
                        plain
                        icon="el-icon-caret-left"
                        @click="currDate = new Date(currDate).setDate(new Date(currDate).getDate() - 7)">
                    </el-button>
                    <el-date-picker v-model="currDate" type="week" format="yyyy 第 WW 周" :clearable="false">
                    </el-date-picker>
                    <el-date-picker v-model="dateRange" type="daterange" range-separator="至" readonly>
                    </el-date-picker>
                    <el-button
                        plain
                        icon="el-icon-caret-right"
                        @click="currDate = new Date(currDate).setDate(new Date(currDate).getDate() + 7)">
                    </el-button>
                    <el-input
                        placeholder="搜尋員工"
                        type="text"
                        style="width: 20em; margin-left: 20px"
                        v-model="search"
                        clearable></el-input>
                    <el-button type="primary" plain style="margin-left: 1em" @click="exportCSV">導出 CSV</el-button>
                </el-form>
            </el-card>

            <el-table :data="tableData" height="650" @cell-click="handleCellClick">
                <el-table-column label="員工">
                    <template slot-scope="scope">
                        <el-row>
                            <el-col :span="8">
                                <el-avatar :size="40" :src="circleUrl"></el-avatar>
                            </el-col>
                            <el-col :span="16" name="empName">
                                <div>{{ scope.row.name }}</div>
                                <div>{{ scope.row.eng_name }}</div>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column v-for="(date, weekIdx) in col" :key="date">
                    <template slot="header"> {{ date }}<br />{{ weekday[weekIdx] }} </template>
                    <template slot-scope="scope">
                        <div :id="date">
                            <el-tag
                                :type="StatType(scope.row, 'workStat', weekIdx)"
                                v-if="workStat(scope.row, date, weekIdx)"
                                >{{ workStat(scope.row, date, weekIdx) }}</el-tag
                            ><br />
                            <el-tag
                                :type="StatType(scope.row, 'leaveStat', weekIdx)"
                                v-if="leaveStat(scope.row, date, weekIdx)"
                                >{{ leaveStat(scope.row, date, weekIdx) }}</el-tag
                            >
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <table id="csv" v-show="false" style="border: 1px solid black; margin-top: 100%">
                <tbody>
                    <tr>
                        <td colspan="37" rowspan="4" id="title">2022-07 通勤報表</td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                    <tr id="columns">
                        <td rowspan="2" colspan="1">姓名</td>
                        <td colspan="3" rowspan="1">請假</td>
                        <td colspan="2" rowspan="1">備註</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>有薪假</td>
                        <td>事假</td>
                        <td>病假</td>
                        <td>遲到</td>
                        <td>曠工</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        <el-dialog :visible.sync="dialogVisible" width="25%" title="編輯出勤紀錄" top="10px">
            <el-form label-width="80px" ref="editForm" v-model="editForm">
                <el-form-item label="員工">
                    <el-input type="text" v-model="editForm.name" disabled> </el-input>
                </el-form-item>
                <el-form-item label="日期">
                    <el-date-picker v-model="editForm.date" style="width: 100%"> </el-date-picker>
                </el-form-item>
                <el-form-item label="上班">
                    <el-time-picker v-model="editForm.workTimestamp" style="width: 100%"> </el-time-picker>
                </el-form-item>
                <el-form-item label="上班狀態">
                    <el-select v-model="editForm.workStat" style="width: 100%" clearable>
                        <el-option label="上班正常" value="上班正常"></el-option>
                        <el-option label="上班缺卡" value="上班缺卡"></el-option>
                        <el-option label="遲到" value="遲到"></el-option>
                        <el-option label="曠工" value="曠工"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="下班">
                    <el-time-picker v-model="editForm.leaveTimestamp" style="width: 100%"> </el-time-picker>
                </el-form-item>
                <el-form-item label="下班狀態">
                    <el-select v-model="editForm.leaveStat" style="width: 100%" clearable>
                        <el-option label="下班正常" value="下班正常"></el-option>
                        <el-option label="下班缺卡" value="下班缺卡"></el-option>
                        <el-option label="早退" value="早退"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="updateRecord" plain>更新</el-button>
                    <el-button type="info" @click="dialogVisible = !dialogVisible" plain>關閉</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import app from '@/firebase';
import $ from 'jquery';

import 'table2excel';
const Table2Excel = window.Table2Excel;

export default {
    data() {
        return {
            table: [],
            shift: [],
            roster: [],
            loading_count: 0,
            search: '',
            page: 1,
            pageSize: 9,
            currDate: new Date().setHours(0, 0, 0, 0),
            weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            startDay: null,
            endDay: null,
            dateRangeList: [],
            updateView: false,
            csv: {
                title: '',
                labels: {
                    name: { title: '姓名' },
                    late: { title: '遲到' },
                    absent: { title: '曠工' },
                    eventLeave: { title: '事假' },
                    sickLeave: { title: '病假' },
                    paidLeave: { title: '年假' },
                },
                data: [],
            },
            publicHoliday: [],
            showDevOption: false,
            uid: '',
            editForm: {
                name: null,
                uid: null,
                date: null,
                workStat: null,
                leaveStat: null,
                workTimestamp: null,
                leaveTimestamp: null,
            },
            dialogVisible: false,
        };
    },
    methods: {
        exportCSV() {
            var table2excel = new Table2Excel();
            table2excel.export(document.querySelectorAll('table#csv'));
        },
        getCSVJson() {
            var d = new Date();
            var _firstDay = new Date(d.getFullYear(), d.getMonth() + 1, 1);
            var _lastday = new Date(_firstDay.setDate(_firstDay.getDate() - 1)).getDate();
            for (let i = 1; i <= _lastday; i++) {
                $('tr#columns').append(`<td rowspan='2'>${i}</td>`);
            }

            /** Setup labels */

            var lastday = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();

            for (let i = 1; i <= lastday; i++) {
                this.csv.labels[`_${i}_`] = {
                    title: i,
                };
            }
            // Name
            this.loading_count++;
            this.csv.data = [];

            this.table.forEach((user) => {
                const userObj = user;
                var _absent = 0;
                var _late = 0;
                var _sickLeave = 0;
                var _eventLeave = 0;
                var _paidLeave = 0;
                var dataObj = {};
                dataObj['name'] = userObj.name;
                const rosterObj = this.roster[userObj.rosterID];

                for (let i = 1; i <= lastday; i++) {
                    const currDate = `${app.formatDate(new Date().setDate(i))}`;
                    var r = userObj.attendance[currDate];
                    if (r == void 0) {
                        if (
                            new Date(new Date().setDate(i)).getDay() == 0 ||
                            new Date(new Date().setDate(i)).getDay() == 6 ||
                            (rosterObj && rosterObj.publicHoliday && this.publicHoliday.includes(currDate))
                        ) {
                            dataObj[`_${i}_`] = '休息';
                        } else {
                            dataObj[`_${i}_`] = '正常';
                        }
                    } else {
                        // const leaves = [
                        //     "病假",
                        //     "事假",
                        //     "年假",
                        //     "產假",
                        //     "陪產假",
                        //     "婚假",
                        //     "喪假",
                        //     "哺乳假",
                        //     "其他",
                        // ];
                        dataObj[`_${i}_`] = r.workStat;
                        if (r.workStat == '曠工') _absent++;
                        else if (r.workStat == '遲到') _late++;
                        else {
                            if (r.workStat === '病假') {
                                _sickLeave++;
                            } else if (r.workStat === '事假') {
                                _eventLeave++;
                            } else if (r.workStat !== '上班缺卡' && r.workStat !== '上班正常') {
                                _paidLeave++;
                            }
                        }
                    }
                }

                this.csv.data.push(dataObj);

                $('table#csv>tbody').append(`
                                    <tr id='${dataObj.name}'>
                                        <td>${dataObj.name}</td>
                                        <td>${_paidLeave}</td>
                                        <td>${_eventLeave}</td>
                                        <td>${_sickLeave}</td>
                                        <td>${_late}</td>
                                        <td>${_absent}</td>
                                    </tr>
                                    `);
                for (let i = 1; i <= _lastday; i++) {
                    $(`table>tbody>tr[id='${dataObj.name}']`).append(`<td>${dataObj['_' + i + '_']}</td>`);
                }

                $('table#csv>tbody').append(`</tr>`);
            });
            this.loading_count--;
        },
        async getUsers() {
            this.table = [];
            await firebase
                .database()
                .ref(`users`)
                .once('value')
                .then((s) => s.val())
                .then((v) => {
                    Object.keys(v).forEach((empID) => {
                        const user = v[empID];
                        if (user.role != 'administrator' && user.role != 'developer') {
                            this.table.push({
                                empID: empID,
                                name: user.name,
                                eng_name: user.eng_name,
                                rosterID: user.rosterID,
                                attendance: user.attendance ? user.attendance.record : [],
                                workStat: [],
                                leaveStat: [],
                            });
                        }
                    });
                });
        },
        updateAttendance() {
            this.table.forEach((user) => {
                if (user.rosterID) {
                    var roster = [];
                    Object.values(this.roster[user.rosterID].shifts).forEach((shiftID) => {
                        roster.push({
                            shiftID: shiftID,
                            workTime: this.shift[shiftID] ? this.shift[shiftID].workTime : '',
                            offTime: this.shift[shiftID] ? this.shift[shiftID].offTime : '',
                        });
                    });
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    for (
                        let date = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                        date < today;
                        date.setDate(date.getDate() + 1)
                    ) {
                        const formattedDate = app.formatDate(date.getTime());
                        const day = date.getDay();
                        const isToday = date == today;
                        var update = {
                            workStat: isToday ? '上班缺卡' : '曠工',
                            leaveStat: isToday ? '下班缺卡' : null,
                        };
                        if (
                            roster[day].shiftID == 'rest' ||
                            (this.roster[user.rosterID].publicHoliday && this.publicHoliday.includes(formattedDate))
                        ) {
                            if (user.attendance) {
                                if (user.attendance[formattedDate]) {
                                    this.loading_count++;
                                    firebase
                                        .database()
                                        .ref(`users/${user.empID}/attendance/record/${formattedDate}`)
                                        .remove()
                                        .then(() => {
                                            this.updateView = true;
                                            this.loading_count--;
                                        });
                                }
                            }
                        } else {
                            if (user.attendance) {
                                if (user.attendance[formattedDate]) {
                                    if (
                                        user.attendance[formattedDate].workStat == '上班缺卡' &&
                                        user.attendance[formattedDate].leaveStat == '下班缺卡'
                                    ) {
                                        this.loading_count++;
                                        firebase
                                            .database()
                                            .ref(`users/${user.empID}/attendance/record/${formattedDate}`)
                                            .update(update)
                                            .then(() => {
                                                this.updateView = true;
                                                this.loading_count--;
                                            });
                                    }
                                } else {
                                    this.loading_count++;
                                    firebase
                                        .database()
                                        .ref(`users/${user.empID}/attendance/record/${formattedDate}`)
                                        .update(update)
                                        .then(() => {
                                            this.updateView = true;
                                            this.loading_count--;
                                        });
                                }
                            } else {
                                this.loading_count++;
                                firebase
                                    .database()
                                    .ref(`users/${user.empID}/attendance/record/${formattedDate}`)
                                    .update(update)
                                    .then(() => {
                                        this.updateView = true;
                                        this.loading_count--;
                                    });
                            }
                        }
                    }
                }
            });
        },
        coverData() {
            const empNames = document.querySelectorAll('[name="empName"]');
            empNames.forEach((element) => {
                element.style.setProperty('text-decoration-line', 'line-through');
                element.style.setProperty('text-decoration-thickness', '1rem');
            });
        },
        handleCellClick(row, col, event) {
            const date = event.children[0].children[0].id;
            if (row.attendance[date] == undefined) return;
            this.editForm.uid = row.empID;
            this.editForm.date = date;
            this.editForm.name = row.name;
            this.editForm.workTimestamp = row.attendance[date].workTimestamp
                ? new Date(row.attendance[date].workTimestamp)
                : null;
            this.editForm.leaveTimestamp = row.attendance[date].leaveTimestamp
                ? new Date(row.attendance[date].leaveTimestamp)
                : null;
            this.editForm.workStat = row.attendance[date].workStat;
            this.editForm.leaveStat = row.attendance[date].leaveStat;
            this.dialogVisible = true;
        },
        autoUpdateRecord() {
            this.table.forEach((row) => {
                var record = new Object();
                for (
                    var date = new Date(this.dateRange[0]);
                    date.getTime() <= this.dateRange[1];
                    date.setDate(date.getDate() + 1)
                ) {
                    const formattedDate = app.formatDate(date);
                    if (row.attendance[formattedDate] != undefined && row.attendance[formattedDate].workStat != '兼職打卡')
                        record[formattedDate] = {
                            workStat: app.getWeightedRandom(['上班正常', '上班缺卡', '遲到'], [15, 3, 2]),
                            leaveStat: app.getWeightedRandom(['下班正常', '下班缺卡', '早退'], [15, 3, 2]),
                        };
                }
                this.loading_count++;
                firebase
                    .database()
                    .ref(`users/${row.empID}/attendance/record`)
                    .update(record)
                    .then(() => {
                        this.updateView = true;
                        this.loading_count--;
                    });
            });
        },
        updateRecord() {
            const uid = this.editForm.uid;
            const date = this.editForm.date;
            this.loading_count++;
            firebase
                .database()
                .ref(`users/${uid}/attendance/record/${date}`)
                .update({
                    leaveStat: this.editForm.leaveStat ?? null,
                    workStat: this.editForm.workStat ?? null,
                    workTimestamp: this.editForm.workTimestamp ? new Date(this.editForm.workTimestamp).getTime() : null,
                    leaveTimestamp: this.editForm.leaveTimestamp
                        ? new Date(this.editForm.leaveTimestamp).getTime()
                        : null,
                })
                .then(() => {
                    this.$message({
                        message: `更新成功`,
                        type: 'success',
                    });
                })
                .catch((err) => {
                    this.$message({
                        message: `發生錯誤 ${err.code} : ${err.message}`,
                        type: 'error',
                    });
                })
                .finally(() => {
                    this.dialogVisible = false;
                    this.loading_count--;
                    this.getUsers();
                });
        },
    },
    async mounted() {
        this.loading_count++;
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.uid = user.uid;
                var dbRef = firebase.database().ref('users/' + this.uid);
                dbRef
                    .once('value')
                    .then((snapshot) => {
                        return snapshot.val();
                    })
                    .then((a) => {
                        if (a.role == 'developer') {
                            this.showDevOption = true;
                        }
                    });
            }
        });
        this.publicHoliday = app.getPublicHolidays(new Date().getFullYear());
        this.shift = await app.getShiftList();
        this.roster = await app.getRosterList();
        await this.getUsers();
        this.updateAttendance();
        this.getCSVJson();
        this.loading_count--;
    },
    watch: {
        loading_count(count) {
            if (count == 0 && this.updateView) {
                this.updateView = false;
                this.getUsers();
            }
        },
    },
    computed: {
        loading() {
            return this.loading_count != 0;
        },
        total() {
            return this.tableData.length;
        },
        tableData() {
            return this.table.filter(
                (data) => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase())
            );
        },
        dateRange() {
            const date = new Date(this.currDate);
            const day = date.getDay();
            return [date.setDate(date.getDate() - day), date.setDate(date.getDate() + 6)];
        },
        col() {
            const list = [];
            const date = new Date(this.dateRange[0]);
            for (var i = 0; i < 7; i++) {
                list.push(
                    date.getFullYear() +
                        '-' +
                        ('00' + (date.getMonth() + 1)).slice(-2) +
                        '-' +
                        ('00' + date.getDate()).slice(-2)
                );
                date.setDate(date.getDate() + 1);
            }
            return list;
        },
        workStat() {
            console.log();
            console.log(this.roster);
            return (row, date, week) => {
                if (
                    row.rosterID &&
                    this.roster[row.rosterID] &&
                    (this.roster[row.rosterID].shifts[week] == 'rest' ||
                        (this.roster[row.rosterID].publicHoliday && this.publicHoliday.includes(date)))
                ) {
                    this.roster[row.rosterID];
                    return (row.workStat[week] = '休息');
                }

                if (row.attendance && row.attendance[date]) {
                    return (row.workStat[week] = row.attendance[date].workStat);
                }
            };
        },
        leaveStat() {
            return (row, date, week) => {
                if (row.attendance && row.attendance[date]) {
                    return (row.leaveStat[week] = row.attendance[date].leaveStat);
                }
            };
        },
        StatType() {
            return (row, type, week) => {
                if (row[type]) {
                    switch (row[type][week]) {
                        case '上班正常':
                        case '下班正常':
                        case '打卡成功':
                        case '正常':
                            return 'success';
                        case '遲到':
                        case '早退':
                            return 'warning';
                        case '曠工':
                            return 'danger';
                        case '上班缺卡':
                        case '下班缺卡':
                        case '休息':
                            return 'info';
                        default:
                            return 'primary';
                    }
                }
            };
        },
    },
};
</script>
