<template>
  <div class="main">
    <el-card class="card-box" shadow="never" v-loading="loading">
      <div slot="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
          <el-breadcrumb-item>考勤管理</el-breadcrumb-item>
          <el-breadcrumb-item>班次設定</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <el-table :data="pagedTableData">
        <el-table-column
          prop="name"
          label="班次名稱"
          sortable></el-table-column>
        <el-table-column
          prop="workTime"
          label="上班時間"
          sortable></el-table-column>
        <el-table-column
          prop="offTime"
          label="下班時間"
          sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot="header">
            <el-button
              circle
              style="margin-left: 1em"
              icon="el-icon-plus"
              plain
              @click="handleClick()"></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              @click="editShift(scope)"
              class="el-icon-edit-outline"
              circle
              plain
              type="primary"></el-button>

            <el-popconfirm
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="確定要刪除此班次嗎？"
              style="margin-left: 1em"
              @confirm="confirmDelete(scope)">
              <el-button
                slot="reference"
                icon="el-icon-delete"
                circle
                plain
                type="danger"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-1">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :visible.sync="dialogVisible" title="新增班次" width="30%">
      <el-form v-model="form" label-width="80px">
        <el-form-item label="班次">
          <el-input
            v-model="form.name"
            placeholder="輸入名稱"
            style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="上班時間" label-width="80px">
          <el-time-picker
            v-model="form.workTime"
            value-format="HH:mm"
            placeholder="上班時間">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="下班時間" label-width="80px">
          <el-time-picker
            v-model="form.offTime"
            value-format="HH:mm"
            placeholder="下班時間">
          </el-time-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onSubmit">確定</el-button
          ><el-button @click="dialogVisible = !dialogVisible">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
  name: "Departments",
  data() {
    return {
      table: [],
      filter_input: "",
      dialogVisible: false,
      form: {
        name: "",
        workTime: "",
        offTime: "",
      },
      list: [],
      loading_count: 0,
      search: "",
      page: 1,
      pageSize: 9,
      editing: false,
    };
  },
  methods: {
    handleClick() {
      this.form = {
        name: "",
        workTime: "",
        offTime: "",
      };
      this.dialogVisible = true;
    },
    loadTable() {
      this.table = [];
      this.loading_count++;
      firebase
        .database()
        .ref("company/attendance/shift")
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          Object.keys(value).forEach((shiftID) => {
            const shift = value[shiftID];
            this.table.push({
              name: shift.name,
              workTime: shift.workTime,
              offTime: shift.offTime,
              shiftID: shiftID,
            });
          });
        })
        .finally(() => {
          this.loading_count--;
        });
    },
    confirmDelete(scope) {
      this.loading_count++;
      const shiftID = scope.row.shiftID;
      firebase
        .database()
        .ref(`company/attendance/shift/${shiftID}`)
        .remove()
        .then(() => {
          this.$message({
            message: `成功刪除班次`,
            type: "success",
          });
        })
        .catch((err) => {
          this.$message({
            message: `發生錯誤 ${err.code}: ${err.message}`,
            type: "danger",
          });
        })
        .finally(() => {
          this.loadTable();
          this.loading_count--;
        });
    },
    editShift(scope) {
      const shiftID = scope.row.shiftID;
      this.dialogVisible = true;
      this.editing = true;
      this.loading_count++;
      firebase
        .database()
        .ref(`company/attendance/shift/${shiftID}`)
        .once("value")
        .then((snapshot) => {
          return snapshot.val();
        })
        .then((v) => {
          this.form.name = v.name;
          this.form.workTime = v.workTime;
          this.form.offTime = v.offTime;
          this.form.shiftID = shiftID;
        })
        .catch((err) => {
          this.$message({
            message: `${err.code}: ${err.message}`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_count--;
        });
    },
    onSubmit() {
      this.loading_count++;
      const shiftRef = firebase.database().ref("company/attendance/shift");
      const shift = {
        name: this.form.name,
        workTime: this.form.workTime,
        offTime: this.form.offTime,
        workHour:
          Math.round(
            (parseInt(this.form.offTime.slice(0, 2)) -
              parseInt(this.form.workTime.slice(0, 2)) +
              (parseInt(this.form.offTime.slice(3, 5)) -
                parseInt(this.form.workTime.slice(3, 5))) /
                60) *
              100
          ) / 100,
      };

      if (this.editing) {
        shiftRef
          .child(this.form.shiftID)
          .update(shift)
          .then(() => {
            this.$message({
              message: "成功修改班次",
              type: "success",
            });
          })
          .catch((err) => {
            this.$message({
              message: `發生錯誤 ${err.code}: ${err.message}`,
              type: "error",
            });
          })
          .finally(() => {
            this.editing = false;
            this.loadTable();
            this.dialogVisible = false;
            this.loading_count--;
          });
      } else {
        shiftRef
          .push(shift)
          .then(() => {
            this.$message({
              message: "成功新增班次",
              type: "success",
            });
          })
          .catch((err) => {
            this.$message({
              message: `發生錯誤 ${err.code}: ${err.message}`,
              type: "error",
            });
          })
          .finally(() => {
            this.loadTable();
            this.dialogVisible = false;
            this.loading_count--;
          });
      }
    },
    handleCurrentChange(c) {
      this.page = c;
    },
  },
  mounted() {
    this.loadTable();
  },
  computed: {
    total() {
      return this.tableData.length;
    },
    tableData() {
      return this.table.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    pagedTableData() {
      return this.tableData.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
    loading() {
      return this.loading_count != 0;
    },
  },
};
</script>
