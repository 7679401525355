import firebase from "firebase/compat/app";
import holidays from "date-holidays";

const getRandom = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

const getWeightedRandom = (item, weight) => {
  const cumulative = [];
  for (let i = 0; i< weight.length; i++) {
    cumulative[i] = weight[i] + (cumulative[i-1] || 0); 
  }
  const random = Math.random() * cumulative[cumulative.length -1];
  for (let i = 0; i< cumulative.length; i++) {
    if (cumulative[i] > random) {
      return item[i];
    }
  }
}

/** format Time */
const formatTime = (ts) => {
  var d = new Date(ts);
  return d.toString().slice(16, 24);
};
/** format Date */
const formatDate = (ts) => {
  let d = new Date(ts);
  return (
    formatTen(d.getFullYear()) +
    "-" +
    formatTen(d.getMonth() + 1) +
    "-" +
    formatTen(d.getDate())
  );
};
/** format 10 */
const formatTen = (n) => {
  return n < 10 ? "0" + n : n;
};
/** format */
const format = (ts) => {
  return formatDate(ts) + " " + formatTime(ts);
};

const approveLeave = async (userID, leaveID) => {
  const leavesRef = firebase
    .database()
    .ref("company/leaves/records/" + userID + "/" + leaveID);
  await leavesRef
    .update({
      status: "Approved",
    })
    .then(() => {
      leavesRef
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          const leaveType = value.type;
          const startTimestamp = value.startTimestamp;
          const endTimestamp = value.endTimestamp;
          var dates = [];
          if (formatDate(startTimestamp) == formatDate(endTimestamp))
            // 同一天
            dates.push(formatDate(startTimestamp));
          else {
            const tsDiff =
              new Date(formatDate(startTimestamp)).getTime() -
              new Date(formatDate(endTimestamp)).getTime();
            const dayDiff = tsDiff / -86400000;

            for (let i = 0; i <= dayDiff; i++) {
              let temp_date = new Date().setDate(
                new Date(formatDate(startTimestamp)).getDate() + i
              );
              dates.push(formatDate(temp_date));
            }
          }

          for (const date in dates) {
            firebase
              .database()
              .ref(`users/${userID}/attendance/record/${dates[date]}`)
              .once("value")
              .then((_snapshot) => _snapshot.val())
              .then((_value) => {
                if (_value == null) {
                  // 未來日子
                  firebase
                    .database()
                    .ref(`users/${userID}/attendance/record/${dates[date]}`)
                    .push({
                      workStat: leaveType,
                    });
                } else {
                  firebase
                    .database()
                    .ref(
                      `users/${userID}/attendance/record/${dates[date]}`
                    )
                    .update({
                      workStat: leaveType,
                    });
                }
              });
          }
        });
      //firebase.database().ref('users/'+ userID + '/attendance/record/' + )
    });
};

const rejectLeave = async (userID, leaveID) => {
  const leavesRef = firebase
    .database()
    .ref("company/leaves/records/" + userID + "/" + leaveID);
  await leavesRef.update({
    status: "Rejected",
  });
};

const deleteLeave = async (userID, leaveID) => {
  const leavesRef = firebase
    .database()
    .ref("company/leaves/records/" + userID + "/" + leaveID);
  await leavesRef.remove();
};

const updateCompany = async (form) => {
  var updates = {
    address: form.address,
    email: form.email,
    name: form.name,
    phone: form.teleNo,
    website: form.url,
  };
  firebase.database().ref("company/info").update(updates);
};

const getLeaveList = async () => {
  var leaves = [];
  const leavesRef = firebase.database().ref("company/leaves/records");
  await leavesRef
    .once("value")
    .then((snapshot) => snapshot.val())
    .then((value) => {
      Object.keys(value).forEach((userID) => {
        const userLeavesObj = value[userID];
        Object.keys(userLeavesObj).forEach((leaveID) => {
          const leaveObj = userLeavesObj[leaveID];
          firebase
            .database()
            .ref("users/" + userID)
            .once("value")
            .then((_snapshot) => _snapshot.val())
            .then((_value) => {
              const name = _value.name;
              leaves.push({
                description: leaveObj.description,
                created_at: format(leaveObj.created_at),
                startTimestamp: format(leaveObj.startTimestamp),
                endTimestamp: format(leaveObj.endTimestamp),
                type: leaveObj.type,
                status: leaveObj.status,
                name: name,
                userID: userID,
                leaveID: leaveID,
              });
            })
            .catch(() => {});
        });
      });
    })
    .catch(() => {});
  return leaves;
};

const getUserList = async () => {
  var userList = [];
  const usersRef = firebase.database().ref("users");
  await usersRef
    .once("value")
    .then((snapshot) => snapshot.val())
    .then((value) => {
      Object.keys(value).forEach((userID) => {
        const user = value[userID];
        if (user.role != "administrator" && user.role != "developer") {
          userList.push({
            label: user.name,
            value: userID,
          });
        }
      });
    })
    .catch(() => {});
  return userList;
};

const getProcurements = async () => {
  var table = [];
  await firebase
    .database()
    .ref("company/purchases/records")
    .once("value")
    .then((snapshot) => snapshot.val())
    .then((value) => {
      Object.keys(value).forEach((userID) => {
        const records = value[userID];
        Object.keys(records).forEach((procurementID) => {
          const procurementObj = records[procurementID];
          firebase
            .database()
            .ref("users/" + userID)
            .once("value")
            .then((_snapshot) => _snapshot.val())
            .then((_value) => {
              table.push({
                user: _value.name,
                uid: userID,
                status: procurementObj.status,
                created_at: format(procurementObj.created_at),
                description: procurementObj.description,
                amount: procurementObj.price,
                type: procurementObj.type,
                create_at_timestamp: procurementObj.created_at,
                procurementID: procurementID,
              });
            });
        });
      });
    })
    .catch(() => {});
  return table;
};

const approveProcurement = async (userID, procurementID) => {
  const leavesRef = firebase
    .database()
    .ref("company/purchases/records/" + userID + "/" + procurementID);
  await leavesRef.update({
    status: "Approved",
  });
};

const rejectProcurement = async (userID, procurementID) => {
  const leavesRef = firebase
    .database()
    .ref("company/purchases/records/" + userID + "/" + procurementID);
  await leavesRef.update({
    status: "Rejected",
  });
};

const deleteProcurement = async (userid, procurementID) => {
  await firebase
    .database()
    .ref("company/purchases/records/" + userid + "/" + procurementID)
    .remove();
};

const getClaims = async () => {
  let arr = [];
  await firebase
    .database()
    .ref("company/claims/records/")
    .once("value")
    .then((snapshot) => snapshot.val())
    .then((value) => {
      Object.keys(value).forEach((userID) => {
        const records = value[userID];
        Object.keys(records).forEach((claimID) => {
          const claimObj = records[claimID];
          firebase
            .database()
            .ref("users/" + userID)
            .once("value")
            .then((_snapshot) => _snapshot.val())
            .then((_value) => {
              const name = _value.name;
              arr.push({
                user: name,
                claimID: claimID,
                userid: userID,
                created_at: format(claimObj.created_at),
                type: claimObj.type,
                status: claimObj.status,
                amount: claimObj.price,
                description: claimObj.description,
              });
            });
        });
      });
    })
    .catch(() => {});
  return arr;
};

const approveClaim = async (userID, claimID) => {
  const leavesRef = firebase
    .database()
    .ref("company/claims/records/" + userID + "/" + claimID);
  await leavesRef.update({
    status: "Approved",
  });
};

const rejectClaim = async (userID, claimID) => {
  const leavesRef = firebase
    .database()
    .ref("company/claims/records/" + userID + "/" + claimID);
  await leavesRef.update({
    status: "Rejected",
  });
};

const deleteClaim = async (userID, claimID) => {
  await firebase
    .database()
    .ref("company/claims/records/" + userID + "/" + claimID)
    .remove();
};

const getOT = async () => {
  var arr = [];
  await firebase
    .database()
    .ref("company/ot/records")
    .once("value")
    .then((snapshot) => snapshot.val())
    .then((value) => {
      Object.keys(value).forEach((userID) => {
        const records = value[userID];
        Object.keys(records).forEach((otID) => {
          const otObj = records[otID];
          firebase
            .database()
            .ref("users/" + userID)
            .once("value")
            .then((_snapshot) => _snapshot.val())
            .then((_value) => {
              const name = _value.name;

              arr.push({
                OTID: otID,
                uid: userID,
                user: name,
                created_at: format(otObj.created_at),
                description: otObj.description,
                startTimestamp: otObj.startTimestamp,
                start: format(otObj.startTimestamp),
                endTimestamp: otObj.endTimestamp,
                end: format(otObj.endTimestamp),
                status: otObj.status,
                type: otObj.type,
              });
            })
            .catch(() => {});
        });
      });
    })
    .catch(() => {});
  return arr;
};

const approveOT = async (userid, otID) => {
  firebase
    .database()
    .ref("company/ot/records/" + userid + "/" + otID)
    .update({
      status: "Approved",
    });
};

const rejectOT = async (userid, otID) => {
  firebase
    .database()
    .ref("company/ot/records/" + userid + "/" + otID)
    .update({
      status: "Rejected",
    });
};

const deleteOT = async (userid, otID) => {
  firebase
    .database()
    .ref("company/ot/records/" + userid + "/" + otID)
    .remove();
};

const getAdjustment = async () => {
  var table = [];
  await firebase
    .database()
    .ref("company/adjustment/records")
    .once("value")
    .then((snapshot) => snapshot.val())
    .then((value) => {
      Object.keys(value).forEach((userID) => {
        const records = value[userID];
        Object.keys(records).forEach((adjustmentID) => {
          const adjustmentObj = records[adjustmentID];
          firebase
            .database()
            .ref("users/" + userID)
            .once("value")
            .then((_snapshot) => _snapshot.val())
            .then((_value) => {
              table.push({
                userid: userID,
                user: _value.name,
                adjustmentID: adjustmentID,
                timestamp: format(adjustmentObj.startTimestamp),
                status: adjustmentObj.status,
                description: adjustmentObj.description,
                created_at: format(adjustmentObj.created_at),
              });
            })
            .catch(() => {
              console.log(`-----------------------------`);
            });
        });
      });
    })
    .catch(() => {
      console.log("");
    });
  return table;
};

const deleteAdjustment = async (userid, adjustmentID) => {
  firebase
    .database()
    .ref("company/adjustment/records/" + userid + "/" + adjustmentID)
    .remove();
};

const approveAdjustment = async (userid, adjustmentID) => {
  firebase
    .database()
    .ref("company/adjustment/records/" + userid + "/" + adjustmentID)
    .update({
      status: "Approved",
    })
    .then(() => {
      firebase
        .database()
        .ref("company/adjustment/records/" + userid + "/" + adjustmentID)
        .once("value")
        .then((snapshot) => snapshot.val())
        .then((value) => {
          const timestamp = value.startTimestamp;
          var date = formatDate(timestamp);
          let update = {};
          if (value.type == "work") {
            update = {
              workTimestamp: timestamp,
              workStat: "上班補卡",
            };
          } else {
            update = {
              leaveTimestamp: timestamp,
              leaveStat: "下班補卡",
            };
          }

          firebase
            .database()
            .ref(`users/${userid}/attendance/record/${date}`)
            .once("value")
            .then((s) => s.val())
            .then((v) => {
              if (v.workStat == "曠工") {
                if (value.type == "work") {
                  update.leaveStat = "下班缺卡";
                } else {
                  update.workStat = "上班缺卡";
                }
              }

              firebase
                .database()
                .ref(`users/${userid}/attendance/record/${date}`)
                .update(update);
            });
        });
    });
};

const rejectAdjustment = async (userid, adjustmentID) => {
  firebase
    .database()
    .ref("company/adjustment/records/" + userid + "/" + adjustmentID)
    .update({
      status: "Rejected",
    });
};

const getShiftList = async () => {
  var table = [];
  await firebase
    .database()
    .ref(`company/attendance/shift`)
    .once("value")
    .then((snapshot) => snapshot.val())
    .then((value) => {
      for (const shiftID in value) {
        table[shiftID] = value[shiftID];
      }
    });
  return table;
};

const getRosterList = async () => {
  var table = [];
  await firebase
    .database()
    .ref(`company/attendance/roster`)
    .once("value")
    .then((snapshot) => snapshot.val())
    .then((value) => {
      for (const rosterID in value) {
        table[rosterID] = value[rosterID];
      }
    });
  return table;
};

const getPublicHolidays = (year) => {
  var list = [];
  var hd = new holidays();
  hd.init("HK");
  list = hd.getHolidays(year).map((e) => {
    return e.date.slice(0, 10);
  });
  return list;
};

export default {
  getRandom,
  getWeightedRandom,
  formatTime,
  formatDate,
  format,
  formatTen,

  getUserList,
  updateCompany,
  getShiftList,
  getRosterList,

  /** 請假 */
  getLeaveList,
  deleteLeave,
  approveLeave,
  rejectLeave,

  /** 採購 */
  getProcurements,
  rejectProcurement,
  approveProcurement,
  deleteProcurement,

  /** 報銷 */
  getClaims,
  deleteClaim,
  approveClaim,
  rejectClaim,

  /** OT */
  getOT,
  approveOT,
  rejectOT,
  deleteOT,

  /** 補卡 */
  getAdjustment,
  deleteAdjustment,
  approveAdjustment,
  rejectAdjustment,

  getPublicHolidays,
};
