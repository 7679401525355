<template>
    <div class="main">
        <el-dialog :visible.sync="visibleDialog" title="審核" width="20%">
            <center class="mt-2">
                <el-button
                    type="success"
                    @click="approveAdjustment"
                    style="width: 40%"
                    icon="el-icon-check"
                    plain
                    >批准</el-button
                >
                <el-button
                    icon="el-icon-close"
                    type="danger"
                    style="width: 40%"
                    @click="rejectAdjustment"
                    plain
                    >駁回</el-button
                >
            </center>
            <center class="mt-1">
                <el-button type="text" @click="dialogVisible = !dialogVisible"
                    >稍後處理</el-button
                >
            </center>
        </el-dialog>
        <el-card class="card-box" shadow="never" v-loading="loading">
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>審核</el-breadcrumb-item>
                    <el-breadcrumb-item>補卡</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card>
                <div slot="header">篩選</div>
                <el-form inline>
                    <el-form-item>
                        <el-select
                            v-model="filter.user"
                            clearable
                            placeholder="員工"
                            filterable
                        >
                            <el-option
                                v-for="(user, i) in userList"
                                :key="i"
                                :label="user.label"
                                :value="user.label"
                            ></el-option></el-select
                    ></el-form-item>
                    <el-form-item
                        ><el-date-picker
                            v-model="filter.date"
                            clearable
                            placeholder="申請日期"
                        ></el-date-picker></el-form-item
                    ><el-form-item
                        ><el-select v-model="filter.status"> </el-select
                    ></el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" height="500">
                <el-table-column sortable label="員工" prop="user">
                </el-table-column>
                <el-table-column
                    sortable
                    prop="created_at"
                    label="申請日期"
                ></el-table-column>
                <el-table-column sortable prop="description" label="申請原因">
                </el-table-column>
                <el-table-column
                    sortable
                    prop="timestamp"
                    label="補卡時間"
                ></el-table-column>
                <el-table-column label="狀態">
                    <template slot-scope="scope">
                        <el-tag
                            v-if="scope.row.status === 'Pending'"
                            type="warning"
                            >待處理</el-tag
                        >
                        <el-tag
                            v-if="scope.row.status === 'Approved'"
                            type="success"
                            >批准</el-tag
                        >
                        <el-tag
                            v-if="scope.row.status === 'Rejected'"
                            type="info"
                            >駁回</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            icon="el-icon-edit"
                            plain
                            @click="handleEdit(scope)"
                            circle
                        ></el-button>
                        <el-popconfirm
                            cancel-button-text="不用了"
                            confirm-button-text="好的"
                            @confirm="handleDelete(scope)"
                            style="margin-left: 1em"
                            title="確定要刪除此紀錄"
                        >
                            <el-button
                                type="danger"
                                icon="el-icon-delete"
                                plain
                                slot="reference"
                                circle
                            ></el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import app from "@/firebase";

export default {
    name: "Adjustment",
    data() {
        return {
            loading_count: 0,
            table: [],
            userList: [],
            filter: {
                user: "",
                date: "",
                status: "",
            },
            visibleDialog: false,
            form: {
                userid: "",
                adjustmentID: "",
            },
        };
    },
    computed: {
        loading() {
            return !!this.loading_count;
        },
        tableData() {
            return this.table.filter((data) => {
                var a =
                    (this.filter.user &&
                        data.user.toLowerCase().includes(this.filter.user)) ||
                    !this.filter.user;
                var b =
                    (this.filter.date &&
                        app.formatDate(new Date(data.timestamp)) ==
                            app.formatDate(this.filter.date)) ||
                    !this.filter.date;

                return a && b;
            });
        },
    },
    mounted() {
        this.getAdjustment();
        this.getUserList();
    },
    methods: {
        handleEdit(scope) {
            this.form.userid = scope.row.userid;
            this.visibleDialog = true;
            this.form.adjustmentID = scope.row.adjustmentID;
        },
        approveAdjustment() {
            this.loading_count++;
            app.approveAdjustment(
                this.form.userid,
                this.form.adjustmentID
            ).then(() => {
                this.loading_count--;
                this.getAdjustment();
                this.visibleDialog = false;
            });
        },
        rejectAdjustment() {
            this.loading_count++;
            app.rejectAdjustment(this.form.userid, this.form.adjustmentID).then(
                () => {
                    this.loading_count--;
                    this.getAdjustment();
                    this.visibleDialog = false;
                }
            );
        },
        handleDelete(scope) {
            const userid = scope.row.userid;
            const adjustmentID = scope.row.adjustmentID;
            this.loading_count++;
            app.deleteAdjustment(userid, adjustmentID).then(() => {
                this.loading_count--;
                this.getAdjustment();
            });
        },
        getUserList() {
            this.loading_count++;
            app.getUserList().then((value) => {
                this.userList = value;

                this.loading_count--;
            });
        },
        getAdjustment() {
            this.loading_count++;
            app.getAdjustment().then((value) => {
                this.table = value;
                this.loading_count--;
            });
        },
    },
};
</script>
