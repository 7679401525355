<template>
    <div class="main">
        <el-card class="card-box" shadow="never" v-loading="loading">
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>部門</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <el-table :data="pagedTableData" id="table">
                <el-table-column
                    prop="name"
                    label="部門"
                    sortable
                ></el-table-column>
                <el-table-column
                    prop="manager"
                    label="主管"
                    sortable
                ></el-table-column>
                <el-table-column
                    prop="num"
                    label="員工"
                    sortable
                ></el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot="header">
                        <el-input
                            placeholder="搜尋部門/主管"
                            type="text"
                            style="float: left; width: 20em"
                            v-model="search"
                            clearable
                        ></el-input>
                        <el-button
                            @click="dialogVisible = !dialogVisible"
                            icon="el-icon-plus"
                            circle
                            style="float: left"
                            type="text"
                        ></el-button>
                    </template>
                    <template slot-scope="scope">
                        <el-button
                            @click="handleClick(scope)"
                            class="el-icon-edit-outline viewDept"
                            circle
                            plain
                            type="primary"
                        ></el-button>

                        <el-popconfirm
                            confirm-button-text="好的"
                            cancel-button-text="不用了"
                            icon="el-icon-info"
                            icon-color="red"
                            title="確定要刪除此部門嗎？"
                            style="margin-left: 1em"
                            @confirm="confirmDelete(scope)"
                        >
                            <el-button
                                slot="reference"
                                icon="el-icon-delete"
                                circle
                                plain
                                type="danger"
                            ></el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <div class="mt-1">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </el-card>
        <el-dialog :visible.sync="dialogVisible" title="新增部門" width="30%">
            <el-form v-model="form" label-width="80px">
                <el-form-item label="部門">
                    <el-input
                        v-model="form.name"
                        placeholder="輸入名稱"
                        style="width: 100%"
                    ></el-input>
                </el-form-item>

                <el-form-item label="主管">
                    <el-select
                        v-model="form.manager"
                        filterable
                        placeholder="輸入關鍵詞"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="(item, i) in manager.list"
                            :key="i"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="員工" label-width="80px">
                    <el-select
                        v-model="form.staff"
                        multiple
                        filterable
                        placeholder="輸入關鍵詞"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="(item, i) in staff.list"
                            :key="i"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="onSubmit"
                        >確定</el-button
                    ><el-button @click="dialogVisible = !dialogVisible"
                        >取消</el-button
                    >
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
    name: "Departments",
    data() {
        return {
            table: [],
            filter_input: "",
            dialogVisible: false,
            form: {
                manager: "",
                staff: "",
                name: "",
            },
            list: [],
            staff: {
                options: [],
                lists: [],
                loading: false,
            },
            manager: {
                options: [],
                lists: [],
                loading: false,
            },
            loading: true,
            search: "",
            page: 1,
            pageSize: 9,
        };
    },
    methods: {
        loadTable() {
            this.table = [];
            this.loading = true;
            const departmentRef = firebase
                .database()
                .ref("company/departments");
            departmentRef
                .once("value")
                .then((snapshot) => {
                    return snapshot.val();
                })
                .then((value) => {
                    Object.keys(value).forEach((pushid) => {
                        firebase
                            .database()
                            .ref(`users/${value[pushid].members.manager}`)
                            .once("value")
                            .then((_snapshot) => {
                                return _snapshot.val();
                            })
                            .then((_value) => {
                                this.table.push({
                                    name: value[pushid].name,
                                    manager: _value == null? '暫無': _value.name,
                                    num: value[pushid].members.staff != null ?Object.keys(
                                        value[pushid].members.staff
                                    ).length: 0,
                                    deptID: pushid,
                                });
                            });
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            this.getManager();
            this.getStaff();
        },
        confirmDelete(scope) {
            const deptID = scope.row.deptID;
            const deptRef = firebase
                .database()
                .ref("company/departments/" + deptID);
            deptRef
                .once("value")
                .then((snapshot) => {
                    return snapshot.val();
                })
                .then((v) => {
                    const staff = v.members.staff;
                    for (const s in staff) {
                        const _s = staff[s];
                        const userRef = firebase
                            .database()
                            .ref("/users/" + _s + "/dept_id/");
                        userRef
                            .remove()
                            .then(() => {
                                this.$message({
                                    message: "Success",
                                    type: "success",
                                });
                            })
                            .catch((err) => {
                                this.$message({
                                    message: `${err.message}`,
                                    type: "error",
                                });
                            });
                    }
                });
            deptRef
                .remove()
                .then(() => {
                    this.$message({
                        message: `成功刪除部門`,
                        type: "success",
                    });
                    this.loading = true;
                    this.loadTable();
                })
                .catch((err) => {
                    this.$message({
                        message: `發生錯誤 ${err.code}: ${err.message}`,
                        type: "danger",
                    });
                });
        },
        handleClick(scope) {
            const deptID = scope.row.deptID;
            this.$router.push("/departments/edit/"+deptID)
        },
        async getManager(query) {
            if (query != "") {
                this.manager.loading = true;
                const usersRef = firebase.database().ref("users");
                usersRef
                    .once("value")
                    .then((snapshot) => {
                        return snapshot.val();
                    })
                    .then((val) => {
                        this.manager.list = [];
                        const v = Object.keys(val);

                        v.forEach((_v) => {
                            if (val[_v].role == "user") {
                                firebase
                                    .database()
                                    .ref("company/departments")
                                    .once("value")
                                    .then((_s) => _s.val())
                                    .then((__v) => {
                                        var found = false;
                                        if(__v != null){
                                            for(let i = 0; i < Object.keys(__v).length; i ++){
                                                const ___v = Object.keys(__v)[i];
                                                if(__v[___v].members.staff != null){
                                                    if ((__v[___v].members.staff[_v] == 1 ||  __v[___v].members.manager == _v) == true ) {
                                                        found = true 
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                        if (!found) {
                                            this.manager.list.push({
                                                name: val[_v] == null ? '暫無' : val[_v].name,
                                                value: _v,
                                            });

                                        }
                                    });
                            }
                        });
                        this.manager.loading = false;
                        this.manager.options = this.manager.list;
                    });
            }
        },
        async getStaff(query) {
            if (query != "") {
                this.staff.loading = true;
                const usersRef = firebase.database().ref("users");
                usersRef
                    .once("value")
                    .then((snapshot) => {
                        return snapshot.val();
                    })
                    .then((val) => {
                        this.staff.list = [];
                        const v = Object.keys(val);
                        v.forEach((_v) => {
                            if (val[_v].role == "user") {
                                firebase
                                    .database()
                                    .ref("company/departments")
                                    .once("value")
                                    .then((_s) => _s.val())
                                    .then((__v) => {
                                        var found = false;
                                        if(__v != null && Object.keys(__v).length > 0){
                                            for(let i = 0; i < Object.keys(__v).length; i ++){
                                                const ___v = Object.keys(__v)[i];
                                                if(__v[___v].members.staff != null){
                                                    if ((__v[___v].members.staff[_v] == 1 ||  __v[___v].members.manager == _v) == true ) {
                                                        found = true 
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                        if (!found) {
                                            this.staff.list.push({
                                                name: val[_v].name,
                                                value: _v,
                                            });
                                        }
                                    });
                            }
                        });
                        this.staff.loading = false;
                        this.staff.options = this.staff.list.filter((item) => {
                            return (
                                item.name
                                    .toLowerCase()
                                    .indexOf(query.toLowerCase()) > -1
                            );
                        });
                    });
            }
        },
        onSubmit() {
            const deptName = this.form.name;
            const staff = this.form.staff;
            const manager = this.form.manager;
            const departmentRef = firebase
                .database()
                .ref("company/departments");
            var update = {
                name: deptName,
                members: {
                    manager: manager,
                },
            };
            var key = departmentRef.push().key;

            departmentRef
                .child(key)
                .update(update)
                .then(() => {
                    var updates = {};
                    const staffRef = firebase
                        .database()
                        .ref("/company/departments/" + key + "/members/staff");
                    for (let i = 0; i < staff.length; i++) {
                        var _key = staff[i];
                        const _value = 1;
                        updates[_key] = _value;
                    }
                    staffRef
                        .update(updates)
                        .then(() => {
                            this.$message({
                                message: "成功新增部門",
                                type: "success",
                            });
                            this.form.name = '';
                            this.form.staff = '';
                            this.form.manager = '';
                            this.loadTable();
                            this.dialogVisible = false;
                        })
                        .catch((err) => {
                            this.$message({
                                message: `發生錯誤 ${err.code}: ${err.message}`,
                                type: "error",
                            });
                            this.dialogVisible = false;
                        });
                });
        },
        handleCurrentChange(c) {
            this.page = c;
        },
    },
    mounted() {
        this.loadTable();
    },
    computed: {
        total() {
            return this.tableData.length;
        },
        tableData() {
            return this.table.filter(
                (data) =>
                    !this.search ||
                    data.name
                        .toLowerCase()
                        .includes(this.search.toLowerCase()) ||
                    data.manager
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
            );
        },
        pagedTableData() {
            return this.tableData.slice(
                this.pageSize * this.page - this.pageSize,
                this.pageSize * this.page
            );
        },
    },
};
</script>
