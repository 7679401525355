<template>
    <div class="main">
        <el-dialog :visible.sync='dialogVisible' width='20%' center title='採購審批'>
            <center class='mt-2'><el-button type='success' @click='approveProcurement' style="width: 40%;" icon='el-icon-check' plain>批准</el-button> <el-button icon='el-icon-close' type='danger' style="width: 40%;" @click='rejectProcurement'  plain>駁回</el-button></center>
            <center class='mt-1'><el-button type='text' @click='dialogVisible = !dialogVisible'>稍後處理</el-button> </center>
        </el-dialog>
        <el-card class="card-box" shadow='never' v-loading='loading'>
            <div slot='header'>
                <el-breadcrumb separator='/'>
                    <el-breadcrumb-item to='/'>主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>審核</el-breadcrumb-item>
                    <el-breadcrumb-item>採購</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card shadow="never">
                <div slot="header">篩選</div>
                <el-form :inline="true" ref="filter">
                    <el-form-item
                        ><el-select clearable v-model="filter.user" placeholder='員工'
                            ><el-option
                                v-for="(user, i) in userList"
                                :key="i"
                                :value="user.label" :label='user.label'
                            ></el-option></el-select
                    ></el-form-item>
                    <el-form-item>
                        <el-select clearable v-model='filter.type' placeholder='類型'>
                            <el-option
                                v-for='(type, i) in types'
                                :key='i'
                                :label='type'
                                :value='type'
                                ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select  clearable v-model="filter.status" placeholder='狀態'>
                            <el-option label='待處理' value='Pending'></el-option>
                            <el-option label='批准' value='Approved'></el-option>
                            <el-option label='駁回' value='Rejected'></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" height='600'>
                <el-table-column label="員工" prop="user" sortable>
                </el-table-column>
                <el-table-column label='類型' sortable>
                    <template slot-scope="scope">
                        <el-tag type='primary'>{{scope.row.type}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label='內容' prop='description' sortable>
                </el-table-column>
                <el-table-column label='金額' sortable>
                    <template slot-scope='scope'>
                        HK ${{scope.row.amount}}
                    </template>
                </el-table-column>
                <el-table-column label="申請日期" prop="created_at" sortable>
                </el-table-column>
                <el-table-column label="狀態" sortable>
                    <template slot-scope="scope">
                        <el-tag
                            type="danger"
                            v-if="scope.row.status == 'Rejected'"
                        >
                            駁回
                        </el-tag>
                        <el-tag
                            type="success"
                            v-if="scope.row.status == 'Approved'"
                        >
                            批准
                        </el-tag>
                        <el-tag
                            type="warning"
                            v-if="scope.row.status == 'Pending'"
                        >
                            待處理
                        </el-tag>

                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            plain
                            circle
                            icon="el-icon-edit-outline"
                            @click='handleEdit(scope)'
                        ></el-button>
                        <el-popconfirm
                            cancel-button-text='不用了'
                            confirm-button-text='好的'
                            @confirm='deleteProcurement(scope)'
                            title='確認刪除此採購單？'
                            style="margin-left: 1em"
                            >
                        <el-button
                            type="danger"
                            plain
                            slot='reference'
                            circle
                            icon="el-icon-delete"
                        ></el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import app from '@/firebase'

export default {
    name: 'Procurement',
    data(){
        return {
            filter:{
                status: '',
                user: '',
                type: ''
            },
            table: [
                /**
                 * status: 狀態
                 * created_at: 創建時間
                 * uid: 員工 ID
                 * description: 內容
                 * amount: 金額
                 * type: 類型
                 * procurementID: 採購ID
                 */
            ],
            userList: [],
            types: ['辦公用品', '設備', '家具', '原料', '電子產品'],
            loading_count: 0,
            editForm: {
                userid: null,
                procurementID: null
            },
            dialogVisible: false,

        }
    },
    methods:{
            handleEdit(scope){
            this.dialogVisible = !this.dialogVisible;
            this.editForm.userid = scope.row.uid;
            this.editForm.procurementID = scope.row.procurementID
        },
        approveProcurement(){
            const userid = this.editForm.userid;
            const procurementID = this.editForm.procurementID;

            app.approveProcurement(userid, procurementID).then(()=>{
                this.$message({
                    message: `該採購紀錄的狀態已變更為 批准`,
                    type: 'success'
                })
                this.dialogVisible = false
                this.getProcurements()
            }).catch(err => {
                this.$message({
                    message: `發生錯誤 ${err.code}: ${err.message}`,
                    type: 'error'
                })
            })
        },
        rejectProcurement(){
            const userid = this.editForm.userid;
            const procurementID = this.editForm.procurementID;

            app.rejectProcurement(userid, procurementID).then(()=>{
                this.$message({
                    message: `該採購紀錄的狀態已變更為 批准`,
                    type: 'success'
                })
                this.dialogVisible = false
                this.getProcurements()
            }).catch(err => {
                this.$message({
                    message: `發生錯誤 ${err.code}: ${err.message}`,
                    type: 'error'
                })
            })
        },
        getUsers(){
            this.loading_count++
            app.getUserList().then(value => {
                this.userList = value;
                this.loading_count --
            })
        },
        getProcurements(){
            this.loading_count ++
            app.getProcurements().then(value => {
                this.table = value
                this.loading_count--
            })
        },
        deleteProcurement(scope){
            const procurementID = scope.row.procurementID;
            const uid = scope.row.uid

            app.deleteProcurement(uid, procurementID).then(()=>{
                this.$message({
                    message: `已經刪除該採購單`,
                    type: 'success'
                })
                this.getProcurements();
            }).catch(err => {
                this.$message({
                    message: `發生錯誤 ${err.code} : ${err.message}`,
                    type: 'error'
                })
            })
        }
    },
    mounted(){
        this.getUsers()
        this.getProcurements()
    },
    computed:{
        tableData() {
            return this.table.filter(data => {
                var a = (this.filter.status && data.status == this.filter.status) || !this.filter.status;
                var b = (this.filter.type && data.type == this.filter.type) || !this.filter.type;
                var c = (this.filter.user && data.user == this.filter.user) || !this.filter.user;
                return a && b && c
            })
        },
        loading() { 
            return !!this.loading_count
        }
    }
}
</script>