<template>
  <div class="main container">
    <el-card shadow="never" class="card-box" v-loading="loading">
      <div style="margin: auto; padding-top: 4rem">
        <p style="text-align: center">{{ companyName }} 註冊表</p>

        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          class="centered-form"
        >
          <el-form-item prop="email">
            <el-input
              v-model="form.email"
              placeholder="輸入電郵"
              style="width: 100%"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="form.password"
              style="width: 100%"
              type="password"
              placeholder="輸入密碼"
            ></el-input>
          </el-form-item>
          <el-form-item prop="fullname">
            <el-input
              v-model="form.fullname"
              placeholder="輸入全名"
              type="text"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="eng_name">
            <el-input
              v-model="form.eng_name"
              placeholder="輸入英文名"
              style="width: 100%"
              type="text"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="teleNo">
            <el-input
              v-model="form.teleNo"
              placeholder="輸入電話號碼"
              style="width: 100%"
              type="number"
              :maxlength="8"
              :minlength="8"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="address">
            <el-input v-model="form.address" placeholder="輸入地址"> </el-input>
          </el-form-item>
          <el-form-item prop="dob">
            <el-date-picker
              v-model="form.dob"
              placeholder="輸入出生日期"
              style="width: 100%"
              type="date"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="bank">
            <el-select v-model="form.bank" placeholder="選擇銀行" filterable
              ><el-option
                v-for="(bank, i) in bankList"
                :key="i"
                :value="bank"
                :label="bank"
              >
              </el-option
            ></el-select>
          </el-form-item>
          <el-form-item prop="bankaccount">
            <el-input
              v-model="form.bankaccount"
              placeholder="輸入銀行號碼"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain @click="onSubmit('form')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<style scoped>
.centered-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.el-form-item {
  width: 100%;
  text-align: center;
}
@media (min-width: 500px) {
  .container {
    width: 500px!important;
  }
}
</style>
<script>
import firebase from "firebase/compat/app";
import app from "@/firebase";
export default {
  name: "RegForm",
  data() {
    return {
      form: {
        email: "",
        password: "",
        fullname: "",
        eng_name: "",
        teleNo: "",
        address: "",
        dob: "",
        bank: "",
        bankaccount: "",
      },
      formRules: {
        fullname: [{ required: true, message: "請輸入全名", trigger: "blur" }],
        eng_name: [
          { required: true, message: "請輸入英文名", trigger: "blur" },
        ],
        password: [{ required: true, message: `請輸入密碼`, trigger: "blur" }],
        email: [
          { required: true, message: "請輸入郵箱", trigger: "blur" },
          { type: "email", message: "請輸入郵箱", trigger: "blur" },
        ],
        teleNo: [
          { required: true, message: `請輸入電話號碼`, trigger: "blur" },
        ],
        address: [{ required: true, message: `請輸入地址`, trigger: "blur" }],
        dob: [{ required: true, message: `請輸入出生日期`, trigger: `blur` }],
        bank: [{ required: true, message: `請選擇銀行`, trigger: `blur` }],
        bankaccount: [
          { required: true, message: `請輸入銀行號碼`, trigger: `blur` },
        ],
      },
      bankList: [
        "中國銀行(香港)有限公司",
        "天星銀行有限公司",
        "螞蟻銀行(香港)有限公司",
        "富融銀行有限公司",
        "理慧銀行有限公司",
        "平安壹賬通銀行(香港)有限公司",
        "匯立銀行有限公司",
        "眾安銀行有限公司",
        "Mox Bank",
        "交通銀行(香港)有限公司",
        "東亞銀行有限公司",
        "中信銀行國際有限公司",
        "中國建設銀行(亞洲)股份有限公司",
        "集友銀行有限公司",
        "創興銀行有限公司",
        "花旗銀行(香港)有限公司",
        "大新銀行有限公司",
        "星展銀行(香港)有限公司",
        "富邦銀行(香港)有限公司",
        "恒生銀行有限公司",
        "香港上海滙豐銀行有限公司",
        "中國工商銀行(亞洲)有限公司",
        "南洋商業銀行有限公司",
        "華僑永亨銀行有限公司",
        "大眾銀行(香港)有限公司",
        "上海商業銀行有限公司",
        "渣打銀行(香港)有限公司",
        "大生銀行有限公司",
        "大有銀行有限公司",
        "招商永隆銀行有限公司",
      ],
      loading_count: 0,
    };
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.loading_count++;
          const dob = app.formatDate(new Date(this.form.dob).getTime());
          const empDate = app.formatDate(new Date().getTime());
          const user = {
            name: this.form.fullname,
            eng_name: this.form.eng_name,
            bank: this.form.bank,
            bank_ac: this.form.bankaccount,
            address: this.form.address,
            dob: dob,
            empDate: empDate,
            role: "user",
            salary: {
              basic: 0,
              type: "month",
            },
            tel: this.form.teleNo,
            email: this.form.email,
          };

          firebase
            .auth()
            .createUserWithEmailAndPassword(this.form.email, this.form.password)
            .then((userCredential) => {
              var u = userCredential.user;
              var uid = u.uid;
              const userRef = firebase.database().ref("users/" + uid);
              userRef
                .update(user)
                .then(() => {
                  firebase
                    .firestore()
                    .collection("users")
                    .doc(uid)
                    .set({
                      Name: user.name,
                      ID: uid,
                      name_lower: user.name.toLowerCase(),
                      chats: [],
                      profilePic:
                        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                    })
                    .then(() => {
                      this.$refs["form"].resetFields();
                      this.active = 0;
                      this.$message({
                        message: "成功註冊",
                        type: "success",
                      });
                    })
                    .catch((err) => {
                      this.$message({
                        message: `發生錯誤 ${err.code}: ${err.message}`,
                        type: "error",
                      });
                    });
                })
                .catch((err) => {
                  this.$message({
                    message: "發生錯誤: " + err.message,
                    type: "error",
                  });
                });
            })
            .catch((err) => {
              this.$message({
                message: `${err.message}`,
                type: "danger",
              });
            })
            .finally(() => {
              this.loading_count--;
            });
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    loading() {
      return this.loading_count != 0;
    },
  },
  mounted() {},
};
</script>
