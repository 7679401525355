<template>
    <div class="main">
        <el-dialog :visible.sync='dialogVisible' width='20%' center title='報銷審批'>
            <center class='mt-2'><el-button type='success' @click='approveClaim' style="width: 40%;" icon='el-icon-check' plain>批准</el-button> <el-button icon='el-icon-close' type='danger' style="width: 40%;" @click='rejectClaim'  plain>駁回</el-button></center>
            <center class='mt-1'><el-button type='text' @click='dialogVisible = !dialogVisible'>稍後處理</el-button> </center>
        </el-dialog>
        <el-card class="card-box" shadow='never' v-loading='loading'>
            <div slot='header'>
                <el-breadcrumb separator='/'>
                    <el-breadcrumb-item to='/'>主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>審核</el-breadcrumb-item>
                    <el-breadcrumb-item>報銷</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card shadow="never">
                <div slot="header">篩選</div>
                <el-form :inline="true" ref="filter">
                    <el-form-item
                        ><el-select v-model="filter.user" clearable placeholder='員工'
                            ><el-option
                                v-for="(user, i) in userList"
                                :key="i"
                                :value="user.label"
                                :label='user.label'
                            ></el-option></el-select
                    ></el-form-item>

                    <el-form-item>
                        <el-select v-model='filter.type' clearable placeholder='類型'>
                            <el-option v-for='(type, i) in types'
                                :key='i'
                                :label='type'
                                :value='type'
                                ></el-option>
                        </el-select>
                        
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model='filter.status' clearable placeholder='狀態'>
                            <el-option label='待處理' value='Pending' />
                            <el-option label='批准' value='Approved' />
                            <el-option label='駁回' value='Rejected' />
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" height='600'>
                <el-table-column label="員工" prop="user" sortable> </el-table-column>
                <el-table-column label='類型' sortable>
                    <template slot-scope="scope">
                        <el-tag type='primary'>{{scope.row.type}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label='內容' prop='description' sortable> </el-table-column>
                <el-table-column label='金額' sortable>
                    <template slot-scope='scope'>
                        HK ${{scope.row.amount}}
                    </template>
                </el-table-column>
                <el-table-column label="申請日期" prop="created_at" sortable>
                </el-table-column>
                <el-table-column label="狀態" sortable>
                    <template slot-scope="scope">
                        <el-tag
                            type="danger"
                            v-if="scope.row.status == 'Rejected'"
                        >
                            駁回
                        </el-tag>
                        <el-tag
                            type="success"
                            v-if="scope.row.status == 'Approved'"
                        >
                            批准
                        </el-tag>
                        <el-tag
                            type="warning"
                            v-if="scope.row.status == 'Pending'"
                        >
                            待處理
                        </el-tag>

                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            plain
                            circle
                            @click='handleEdit(scope)'
                            icon="el-icon-edit-outline"
                        ></el-button>
                        <el-popconfirm title='確認要刪除此報銷紀錄嗎？' cancel-button-text='不用了' confirm-button-text='好的'
                            @confirm='deleteClaim(scope)' style="margin-left: 1em">
                            
                            <el-button
                                type="danger"
                                slot='reference'
                                plain
                                circle
                                icon="el-icon-delete"
                            ></el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import app from '@/firebase'

export default {
    name: 'Claim',
    data(){
        return {
            filter: {
                status: '',
                type: '',
                user: ''
            },
            table: [
                
            ],
            loading_count: 0,
            userList: [],
            editForm: {
                userid: null,
                claimID: null,
            },
            dialogVisible: false,
            types: ['辦公用品', '設備', '家具', '原料', '電子產品'],
            
        }
    },
    mounted(){
        this.getClaims()
        this.getUserList()
    },
    methods: {
        approveClaim(){
            const claimID = this.editForm.claimID;
            const userid = this.editForm.userid;
            app.approveClaim(userid, claimID).then(()=>{
                this.$message({
                    message: `該報銷紀錄的狀態已變更為 批准`,
                    type: 'success'
                })
                this.getClaims()
                this.dialogVisible = false
            }).catch(err=>{
                this.$message({
                    message: `發生錯誤 ${err.code}: ${err.message}`,
                    type: 'error'
                })
            })
        },
        rejectClaim(){
            const claimID = this.editForm.claimID;
            const userid = this.editForm.userid;
            app.rejectClaim(userid, claimID).then(()=>{
                this.$message({
                    message: `該報銷紀錄的狀態已變更為 駁回`,
                    type: 'success'
                })
                this.getClaims()
                this.dialogVisible = false
            }).catch(err=>{
                this.$message({
                    message: `發生錯誤 ${err.code}: ${err.message}`,
                    type: 'error'
                })
            })
        },
        deleteClaim(scope){
            const claimID = scope.row.claimID;
            const userid = scope.row.userid;

            this.loading_count ++
            app.deleteClaim(userid, claimID).then(()=> {
                this.loading_count --
                this.getClaims()
                this.$message({
                    message: `已刪除該報銷紀錄`,
                    type: 'success'
                })
            }).catch(err => {
                this.$message({
                    message: `發生錯誤 ${err.code} : ${err.message}`,
                    type: 'error'
                })
            })
        },
        getUserList(){
            this.loading_count ++
            app.getUserList().then(value => {
                this.userList = value
                this.loading_count --
            })
        },
        getClaims(){
            this.loading_count ++
            app.getClaims().then(value => {
                this.table = value
                this.loading_count --
            })
        },
        handleEdit(scope){
            this.dialogVisible = !this.dialogVisible;
            this.editForm.userid = scope.row.userid;
            this.editForm.claimID = scope.row.claimID
        },
        
    },
    computed:{
        tableData(){
            return this.table.filter(data => {
                var a = (this.filter.user && this.filter.user == data.user) || !this.filter.user
                var b = (this.filter.status && this.filter.status == data.status) || !this.filter.status
                var c = (this.filter.type && this.filter.type == data.type) || !this.filter.type
                return a && b && c
            })
        },
        loading() {
            return !!this.loading_count
        }
    }
}
</script>
